import {
  animalBirthUpdateConverter,
  animalBreedUpdateConverter,
  animalConvertor,
  animalDetailsConverter,
  animalGenderUpdateConverter,
  animalHouseTrainedUpdateConverter,
  animalNameUpdateConverter,
  animalPrimaryColorUpdateConverter,
  animalProfilePictureUpdateConverter,
  animalReproductiveUpdateConverter,
  animalSecondaryColorUpdateConverter,
  animalSpeciesUpdateConverter,
  animalWeightUpdateConverter,
  createAnimalConvertor,
  createVetRecordConvertor,
  updateVetRecordDocumentsConvertor,
  getAnimalInfoListConvertor,
  vetRecordExpirationUpdateConverter,
  vetRecordTypeUpdateConverter,
  deleteVetRecordConvertor,
  applicationAnimalConvertor,
  confirmPaymentConvertor,
  updateAnimalTypeConvertor,
  completeAnimalConvertor,
  animalDetailsByIdConverter,
  animalVetRecordConvertor,
  animalMoveOutConvertor,
  unAuthorizedResidencyConverter,
  convertAnimalToPetConverter,
  animalMetadataConverter,
  convertAnimalToESAConverter,
  updateAnimalConversionHPDocsConverter,
  convertAnimalToSAConverter,
  incompleteAnimalsData,
} from 'data/convertors/animal.convertors';
import { pageableDataConverter } from 'data/convertors/pageableData.converters';
import {
  TAnimalBirthDateUi,
  TAnimalBreedUpdateUi,
  TAnimalDetails,
  TAnimalDetailsUi,
  TAnimalFormDataUI,
  TAnimalGenderUpdateUi,
  TAnimalGet,
  TAnimalHouseTrainedUpdateUi,
  TAnimalNameUpdateUi,
  TAnimalPostUI,
  TAnimalPrimaryColorUpdateUi,
  TAnimalProfileUpdateUi,
  TAnimalReproductiveUpdateUi,
  TAnimalSecondaryColorUpdateUi,
  TAnimalSpeciesUpdateUi,
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordsList,
  TAnimalVetRecordsListUi,
  TAnimalWeightUpdateUi,
  TVetRecordDocumentsUI,
  TVetRecordExpirationUpdateUI,
  TVetRecordTypeNameUpdateUI,
  TVetRecordUI,
  TApplicationAnimal,
  TApplicationAnimalUI,
  TConfirmPaymentUi,
  TAnimalTypeUI,
  TCompleteAnimalFormDataUI,
  TAnimalDetailsByIdUi,
  TAnimalDetailsById,
  TMoveOutFormUI,
  TUnAuthorizedResidencyUI,
  TUnAuthorizedResidencyDB,
  TConvertAnimalToPetUI,
  TAnimalMetadata,
  TAnimalMetadataUi,
  TConvertAnimalToEsaUI,
  TUpdateAnimalConvHPDocsUI,
  TConvertAnimalToSaUI,
  TCreateAnimalIncompleteUI,
  TIncompleteAnimalsDataDB,
  TIncompleteAnimalsDataUI,
} from 'data/types/animal.types';
import { TOptionType, TOptionTypeUi } from 'data/types/generalTypes';
import {
  TPageableDataWithContentUi,
  TPageableParamUI,
} from 'data/types/pagableData.type';
import { EAnimalUrls } from 'data/urls/animal.url';
import { EApplicationUrls } from 'data/urls/application.url';
import { authSplitApi } from '../helpers/slice.helpers';
import { incompleteAnimalConverter } from '../../data/convertors/application.converters';

export const animalApi = authSplitApi('animal', [
  'animal',
  'animalMetadata',
  'species',
  'breeds',
  'colors',
  'animals',
  'animalById',
  'vetRecords',
  'applicationAnimal',
  'moveOutPreview',
  'incompleteAnimals',
]).injectEndpoints({
  endpoints: build => ({
    createAnimal: build.mutation<string, TAnimalFormDataUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}`,
          method: 'POST',
          body: createAnimalConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animals'],
    }),
    createIncompleteAnimal: build.mutation<string, TCreateAnimalIncompleteUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.INCOMPLETE}`,
          method: 'POST',
          body: incompleteAnimalConverter.toDb(body),
        };
      },
    }),
    completeAnimal: build.mutation<void, TCompleteAnimalFormDataUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.COMPLETE}`,
          method: 'PUT',
          body: completeAnimalConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animals', 'incompleteAnimals'],
    }),
    createVetRecord: build.mutation<void, TAnimalVetRecordCreateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.ATTACH_VET_RECORD}`,
          method: 'POST',
          body: createVetRecordConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    confirmPayment: build.mutation<void, TConfirmPaymentUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.PAY}`,
          method: 'POST',
          body: confirmPaymentConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    updateVetRecordExpiration: build.mutation<
      void,
      TVetRecordExpirationUpdateUI
    >({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}/${EAnimalUrls.EXPIRATION}`,
          method: 'PATCH',
          body: vetRecordExpirationUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    updateVetRecordType: build.mutation<void, TVetRecordTypeNameUpdateUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}/${EAnimalUrls.TYPE}`,
          method: 'PATCH',
          body: vetRecordTypeUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    getAnimals: build.query<
      TPageableDataWithContentUi<TAnimalPostUI[]>,
      TPageableParamUI
    >({
      query(params) {
        return {
          url: `${EAnimalUrls.ANIMALS}`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: ['animals'],
      transformResponse: (data: TPageableDataWithContentUi<TAnimalGet[]>) => {
        return {
          ...pageableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(animal => animalConvertor.fromDb(animal))
            : [],
        };
      },
    }),
    deleteAnimal: build.mutation<void, string>({
      query(animalId) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${animalId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['animals'],
    }),
    convertAnimalToPet: build.mutation<void, TConvertAnimalToPetUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.TYPE}/${EAnimalUrls.TO_PET}`,
          method: 'PATCH',
          body: convertAnimalToPetConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    convertAnimalToESA: build.mutation<void, TConvertAnimalToEsaUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.TYPE}/${EAnimalUrls.TO_ESA}`,
          method: 'PATCH',
          body: convertAnimalToESAConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    convertAnimalToSA: build.mutation<void, TConvertAnimalToSaUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.TYPE}/${EAnimalUrls.TO_SA}`,
          method: 'PATCH',
          body: convertAnimalToSAConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    updateAnimalConversionHPDocs: build.mutation<
      void,
      TUpdateAnimalConvHPDocsUI
    >({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.CONVERSION}/${EAnimalUrls.HP_DOC}`,
          method: 'PATCH',
          body: updateAnimalConversionHPDocsConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    getAnimal: build.query<TAnimalDetailsUi, string>({
      query(id) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['animal'],
      transformResponse: (data: TAnimalDetails) =>
        animalDetailsConverter.fromDb(data),
    }),
    getIncompleteAnimals: build.query<TIncompleteAnimalsDataUI, void>({
      query() {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.INCOMPLETE}`,
          method: 'GET',
        };
      },
      providesTags: ['incompleteAnimals'],
      transformResponse: (data: TIncompleteAnimalsDataDB) =>
        incompleteAnimalsData.fromDb(data),
    }),
    getAnimalById: build.query<TAnimalDetailsByIdUi, string>({
      query(id) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['animalById'],
      transformResponse: (data: TAnimalDetailsById) =>
        animalDetailsByIdConverter.fromDb(data),
    }),
    getApplicationAnimal: build.query<
      TApplicationAnimalUI,
      { animalId: string; leasePacketId?: string }
    >({
      query({ animalId, leasePacketId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.STEP}/${EApplicationUrls.ANIMAL}/${EApplicationUrls.DETAILS}`,
          method: 'GET',
          params: {
            animalId,
            leasePacketId,
          },
        };
      },
      transformResponse: (data: TApplicationAnimal) =>
        applicationAnimalConvertor.fromDb(data),
      providesTags: ['applicationAnimal'],
    }),
    getAnimalVetRecordsList: build.query<TAnimalVetRecordsListUi[], string>({
      query(id) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${id}/${EAnimalUrls.VET_RECORDS}`,
          method: 'GET',
        };
      },
      providesTags: ['vetRecords'],
      transformResponse: (data: TAnimalVetRecordsList[]) =>
        data?.map(item => animalVetRecordConvertor.fromDb(item)) || [],
    }),
    getAnimalMetadata: build.query<TAnimalMetadataUi, string>({
      query(id) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${id}/${EAnimalUrls.METADATA}`,
          method: 'GET',
        };
      },
      providesTags: ['animalMetadata'],
      transformResponse: (data: TAnimalMetadata) =>
        animalMetadataConverter.fromDb(data),
    }),
    updateVetRecordDocuments: build.mutation<void, TVetRecordDocumentsUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}/${EAnimalUrls.DOCUMENTS}`,
          method: 'PATCH',
          body: updateVetRecordDocumentsConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    updateAnimalType: build.mutation<void, TAnimalTypeUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.TYPE}`,
          method: 'PATCH',
          body: updateAnimalTypeConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals'],
    }),
    deleteVetRecord: build.mutation<void, TVetRecordUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.VET_RECORDS}`,
          method: 'DELETE',
          body: deleteVetRecordConvertor.toDb(body),
        };
      },
      invalidatesTags: ['vetRecords', 'animal', 'applicationAnimal'],
    }),
    getAnimalSpecies: build.query<TOptionTypeUi[], void>({
      query() {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.SPECIES}`,
          method: 'GET',
        };
      },
      providesTags: ['species'],
      transformResponse: (data: TOptionType[]) => {
        return getAnimalInfoListConvertor.fromDb(data);
      },
    }),
    getAnimalBreeds: build.query<TOptionTypeUi[], { speciesId?: string }>({
      query({ speciesId }) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.BREEDS}`,
          method: 'GET',
          params: { speciesId },
        };
      },
      providesTags: ['breeds'],
      transformResponse: (data: TOptionType[]) => {
        return getAnimalInfoListConvertor.fromDb(data);
      },
    }),
    getAnimalColors: build.query<TOptionTypeUi[], void>({
      query() {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.COLORS}`,
          method: 'GET',
        };
      },
      providesTags: ['colors'],
      transformResponse: (data: TOptionType[]) => {
        return getAnimalInfoListConvertor.fromDb(data);
      },
    }),
    updateAnimalProfileImage: build.mutation<void, TAnimalProfileUpdateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/profilePicture`,
          method: 'PATCH',
          body: animalProfilePictureUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalName: build.mutation<void, TAnimalNameUpdateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/name`,
          method: 'PATCH',
          body: animalNameUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalGender: build.mutation<void, TAnimalGenderUpdateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/gender`,
          method: 'PATCH',
          body: animalGenderUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalHouseTrained: build.mutation<void, TAnimalHouseTrainedUpdateUi>(
      {
        query(body) {
          return {
            url: `${EAnimalUrls.ANIMALS}/houseTrained`,
            method: 'PATCH',
            body: animalHouseTrainedUpdateConverter.toDb(body),
          };
        },
        invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
      },
    ),
    updateAnimalBreed: build.mutation<void, TAnimalBreedUpdateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/breed`,
          method: 'PATCH',
          body: animalBreedUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalSpecies: build.mutation<void, TAnimalSpeciesUpdateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/species`,
          method: 'PATCH',
          body: animalSpeciesUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalPrimaryColor: build.mutation<void, TAnimalPrimaryColorUpdateUi>(
      {
        query(body) {
          return {
            url: `${EAnimalUrls.ANIMALS}/primaryColor`,
            method: 'PATCH',
            body: animalPrimaryColorUpdateConverter.toDb(body),
          };
        },
        invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
      },
    ),
    updateAnimalSecondaryColor1: build.mutation<
      void,
      TAnimalSecondaryColorUpdateUi
    >({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/secondaryColor1`,
          method: 'PATCH',
          body: animalSecondaryColorUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalSecondaryColor2: build.mutation<
      void,
      TAnimalSecondaryColorUpdateUi
    >({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/secondaryColor2`,
          method: 'PATCH',
          body: animalSecondaryColorUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalReproductive: build.mutation<void, TAnimalReproductiveUpdateUi>(
      {
        query(body) {
          return {
            url: `${EAnimalUrls.ANIMALS}/reproductive`,
            method: 'PATCH',
            body: animalReproductiveUpdateConverter.toDb(body),
          };
        },
        invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
      },
    ),
    updateAnimalBirth: build.mutation<void, TAnimalBirthDateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/birthDate`,
          method: 'PATCH',
          body: animalBirthUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    updateAnimalWeight: build.mutation<void, TAnimalWeightUpdateUi>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/weight`,
          method: 'PATCH',
          body: animalWeightUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'applicationAnimal'],
    }),
    moveOutAnimal: build.mutation<void, TMoveOutFormUI>({
      query(body) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.MOVE_OUT}`,
          method: 'PATCH',
          body: animalMoveOutConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animals', 'applicationAnimal'],
    }),
    moveOutPreview: build.query<TUnAuthorizedResidencyUI[], string>({
      query(animalId) {
        return {
          url: `${EAnimalUrls.ANIMALS}/${EAnimalUrls.MOVE_OUT}/${EAnimalUrls.PREVIEW}`,
          method: 'GET',
          params: { animalId },
        };
      },
      providesTags: ['moveOutPreview'],
      transformResponse: (data: {
        unAuthorizedResidencies: TUnAuthorizedResidencyDB[];
      }) => {
        return (data.unAuthorizedResidencies || []).map(item =>
          unAuthorizedResidencyConverter.fromDb(item),
        );
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateIncompleteAnimalMutation,
  useCreateAnimalMutation,
  useCompleteAnimalMutation,
  useDeleteAnimalMutation,
  useConvertAnimalToPetMutation,
  useConvertAnimalToESAMutation,
  useConvertAnimalToSAMutation,
  useUpdateAnimalConversionHPDocsMutation,
  useCreateVetRecordMutation,
  useConfirmPaymentMutation,
  useUpdateAnimalTypeMutation,
  useUpdateVetRecordDocumentsMutation,
  useUpdateVetRecordTypeMutation,
  useUpdateVetRecordExpirationMutation,
  useDeleteVetRecordMutation,
  useGetAnimalsQuery,
  useGetAnimalSpeciesQuery,
  useGetAnimalBreedsQuery,
  useGetAnimalColorsQuery,
  useGetAnimalQuery,
  useGetAnimalByIdQuery,
  useGetAnimalMetadataQuery,
  useGetApplicationAnimalQuery,
  useUpdateAnimalProfileImageMutation,
  useUpdateAnimalNameMutation,
  useUpdateAnimalGenderMutation,
  useUpdateAnimalBreedMutation,
  useUpdateAnimalHouseTrainedMutation,
  useUpdateAnimalPrimaryColorMutation,
  useUpdateAnimalSecondaryColor1Mutation,
  useUpdateAnimalSecondaryColor2Mutation,
  useUpdateAnimalReproductiveMutation,
  useUpdateAnimalSpeciesMutation,
  useUpdateAnimalBirthMutation,
  useUpdateAnimalWeightMutation,
  useMoveOutAnimalMutation,
  useMoveOutPreviewQuery,
  useGetIncompleteAnimalsQuery,
} = animalApi;
