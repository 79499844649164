import { Row } from 'components/Base/Grid';
import styled from 'styled-components';

export const SCompleteAnimalBar = styled(Row)`
  margin-top: 65px;
  padding: 8px 50px;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  background-color: #f6911a80;
  @media (max-width: 768px) {
    padding: 8px 20px;
    height: auto;
  }
`;

export const SCompleteAnimalBarContent = styled(Row)`
  width: 1320px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    button: {
      width: 100%;
    }
  }
`;
