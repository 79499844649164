import styled from 'styled-components';
import AntdLayout from 'antd/es/layout';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';
import { Menu } from 'components/Base/Menu';

const { Footer } = AntdLayout;

export const SMenu = styled(Menu)`
  justify-content: end;
`;

export const SFooterWrapper = styled(Footer)`
  & {
    height: 56px;
    background-color: ${Colors.Black};
    padding: 8px 50px;
    width: 100%;
    z-index: 9;
    svg {
      height: 40px;
    }
  }
  .ant-menu {
    background-color: ${Colors.Black};
    &.ant-menu-horizontal {
      border: none;
    }
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: ${Colors.Black};
      }
    }
    .ant-menu-item {
      &:hover {
        :after {
          border: none;
        }
      }
      &:active {
        background-color: ${Colors.Black};
      }
      a {
        color: ${Colors.White};
        font-weight: ${FontWeights.Bold};
        font-size: ${FontSizes.FontMD}px;
        :hover {
          color: ${Colors.PrimaryColor};
          text-decoration: none;
        }
      }
    }
  }
`;
