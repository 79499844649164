import { Moment } from 'moment';
import {
  TLeasePacketPropertyUi,
  TLeasePacketUnitUi,
} from './application.types';
import {
  EAnimalTypes,
  EAnimalTypesUI,
  TAnimalIncompleteUI,
} from './animal.types';

export type TResidencyData = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal: boolean;
  animals: TAnimalResidencyDataDB[];
};

export type TResidencyDataUI = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal?: boolean;
  animalResidencies: TAnimalResidencyDataUI[];
};

export type TCreateTenantResidencyDB = {
  leasePacketId: string;
  propertyName: string;
  unitName: string;
  moveIn: string;
  leaseTo: string;
  hasAnimal?: boolean;
  roommateHasAnimal: boolean;
  animalIds: string[];
};

export type TCreateTenantResidencyUI = {
  leasePacketId: string;
  propertyName: { label: string; value: TLeasePacketPropertyUi };
  unit: { label: string; value: TLeasePacketUnitUi };
  startTime: Moment;
  endTime: Moment;
  haveAnimal: boolean;
  roommateHasAnimal: boolean;
  animals: TAnimalIncompleteUI[];
};

export type TCompleteTenantResidencyUI = {
  residencyId: string;
  haveAnimal: boolean;
  roommateHasAnimal: boolean;
  animals: TAnimalIncompleteUI[];
};
export type TCompleteTenantResidencyDB = {
  residenceId: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  animalIds: string[];
};

export enum EAnimalResidencyStatusUI {
  ACTIVE = 'Approved',
  TERMINATED = 'Moved out',
}

export enum EAnimalResidencyStatusDB {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export enum EResidencyStatusUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
  FORMER_RESIDENT = 'Former resident',
  DENIED = 'Not Approved',
}

export enum EResidencyStatusDB {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
  DENIED = 'DENIED',
}

export type TAnimalResidencyDataUI = {
  id: string;
  name: string;
  status: EAnimalResidencyStatusUI;
  type: EAnimalTypesUI;
};

export type TAnimalResidencyDataDB = {
  animalId: string;
  name: string;
  status: EAnimalResidencyStatusDB;
  type: EAnimalTypes;
};
