import React from 'react';
import { defaultDateFormat } from 'helpers/utils';
import { SDatePicker, SDateRangePicker } from './DatePicker.style';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const DateRangePicker: React.FC<TDateRangePickerProps> = ({
  format = defaultDateFormat,
  ...props
}) => {
  return <SDateRangePicker format={format} {...props} />;
};

const DatePicker: React.FC<TDatePickerProps> = ({
  format = defaultDateFormat,
  ...props
}) => {
  return <SDatePicker format={format} {...props} />;
};

export { DateRangePicker, DatePicker };
