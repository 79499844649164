export enum EAnimalUrls {
  ANIMALS = 'animals',
  METADATA = 'metadata',
  COMPLETE = 'complete',
  SPECIES = 'species',
  BREEDS = 'breeds',
  COLORS = 'colors',
  TYPE = 'type',
  PAY = 'pay',
  CONVERSION = 'conversion',
  EXPIRATION = 'expiration',
  ATTACH_VET_RECORD = 'attach-vet-record',
  VET_RECORDS = 'vetRecords',
  DOCUMENTS = 'documents',
  MOVE_OUT = 'move-out',
  PREVIEW = 'preview',
  TO_PET = 'to-pet',
  TO_ESA = 'to-esa',
  TO_SA = 'to-sa',
  HP_DOC = 'hp-doc',
  INCOMPLETE = 'incomplete',
}
