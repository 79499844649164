import { RoutesPaths } from 'routes/Routes.types';
import { TMenuItem, TMenuActionTypeItem } from './Header.props';

export const menuPathItems: TMenuItem[] = [
  {
    title: 'Profile',
    path: RoutesPaths.USER_PROFILE,
  },
  {
    title: 'Payment',
    path: RoutesPaths.PAYMENT,
  },
];

export const menuActionItems: TMenuActionTypeItem[] = [
  {
    title: 'Logout',
    actionType: 'logout',
  },
];

export enum EGoBackKeys {
  ANIMAL_DETAILS = '/app/animal/:*',
  USER_PROFILE = '/app/user-profile',
  CREATE_APPLICATION = '/app/application/:*',
  CURRICULUM_DETAILS = '/app/curriculum/:*',
  COMPLAINT_DETAILS = '/app/complaint/:*',
}

export const goBackOptions: {
  [key in EGoBackKeys]: { label: string; backTo?: string };
} = {
  [EGoBackKeys.ANIMAL_DETAILS]: {
    label: 'Back to animals page',
    backTo: '/app/animal',
  },
  [EGoBackKeys.USER_PROFILE]: {
    label: 'Back to animals page',
    backTo: '/app/animal',
  },
  [EGoBackKeys.CREATE_APPLICATION]: {
    label: 'Back to animals page',
    backTo: '/app/animal',
  },
  [EGoBackKeys.CURRICULUM_DETAILS]: {
    label: 'Back to curriculum page',
    backTo: '/app/curriculum',
  },
  [EGoBackKeys.COMPLAINT_DETAILS]: {
    label: 'Back to complaint list',
    backTo: '/app/complaint',
  },
};
