import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import WebApisLayer from 'components/Base/WebApisLayer';
import ImpersonateUpdatesLayer from 'components/Base/ImpersonateUpdatesLayer';
import { NotificationsRedirectLayer } from 'components/Shared/NotificationsRedirectLayer';
import { store } from 'redux/store';
import RoutesPage from 'routes';
import ThemeWrapper from 'theme/ThemeWrapper';
import amplifyConfig from 'aws-exports';
import ErrorBoundary from './ErrorBoundary';

Amplify.configure(amplifyConfig);

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <NotificationsRedirectLayer>
            <ThemeWrapper>
              <WebApisLayer>
                <ImpersonateUpdatesLayer>
                  <RoutesPage />
                </ImpersonateUpdatesLayer>
              </WebApisLayer>
            </ThemeWrapper>
          </NotificationsRedirectLayer>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
