export const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const hasESADocumentationOptions = [
  { value: 'Yes', label: 'I have ESA documentation' },
  { value: 'No', label: 'I do not have any ESA documentation' },
];

export const hasSADocumentationOptions = [
  { value: 'Yes', label: 'I have SA health documentation' },
  { value: 'No', label: 'I don’t have  SA documentation' },
];

export const isDisabilityObservableOptions = [
  {
    value: 'Yes',
    label: 'Observable disability',
    subtitle:
      'Observable impairments generally tend to be obvious. Observable impairments include blindness or low vision, deafness or being hard of hearing, mobility limitations, and other types of impairments with observable symptoms or effects.',
  },
  {
    value: 'No',
    label: 'Non observable disability',
    subtitle:
      'Non-Observable Disabilities, impairments that may seem invisible or are not always readily apparent.',
  },
];

export const knownQRLinks = [
  'https://qr.page/g/1JsGfIbmIB8',
  'https://qr.page/g/47c1CCwMhJq',
  'https://qr.page/g/1bIfeVcgCMg',
  'https://forms.gle/vhrSYGcN3pG19zVR6',
  'https://forms.gle/tXaKCJ5RiqgPZ6k38',
  'https://forms.gle/XRfyVfXH4EDo4NdZ9',
  'https://forms.gle/dfXSY36CqNanChYs9',
  'https://forms.gle/aZULcDm9aW94WwaHA',
  'https://forms.gle/2prbPKNQFsaZDMce8',
  'https://forms.gle/Vr9D6J76uqzaf4Nk9',
  'https://forms.gle/mNUjjUADnSfQNFBL6',
  'https://forms.gle/3a1Bztj3R7UdgUEZ6',
  'https://forms.gle/b6GWj42R4bCxTfqp9',
  'https://forms.gle/hYRfwC9LZRA3ydqz6',
  'https://forms.gle/utzT6GV397923po3A',
  'https://forms.gle/JUhSrW8To8Hryd89A',
  'https://forms.gle/3a1Bztj3R7UdgUEZ6',
  'https://forms.gle/P6DMWT8xN4kurfko9',
  'https://forms.gle/5Y2GXjDXMFhMZeEB8',
  'https://forms.gle/Q7ispbWwrwv2k8sd9',
  'https://forms.gle/j8tmtYFhSxuVp1aW8',
  'https://forms.gle/99VyUjqAFNgYrugR6',
  'https://forms.gle/s8Ss6VE3phe4W6Mb8',
  'https://forms.gle/xcXhoiinFxwHNd5K6',
  'https://forms.gle/UPPHgwVB8tCFiHxV6',
  'https://forms.gle/CqHcvoY9Vf6rWtkn6',
  'https://forms.gle/ABcP7HZpmVhUN7vV8',
];

export enum EText {
  DEFAULT_ERROR = 'Something went wrong',
  HP_FORM_TEXT = 'The quickest and most effective method of verifying your documentation is with the Health Professional Form attached below sent immediately to your Health Professional. It does not ask any specific details about the nature of your disability, only that the process is in accordance to the HUD guidelines. If you would prefer to verify the documentation another way please contact us at support@ourpetpolicy.com or (208)906-8886.',
  VACCINATION_OTHER_NO_EXPIRE = 'Vaccination has no expiration.',
  VACCINATION_RABBIES_NO_EXPIRE = 'Documentation is from a licensed veterinarian exempting my animal from a rabies vaccination due to the animals age/health condition.',
  DONT_HAVE_CODE = 'If you do not know the code, please ask the property manager or email support@ourpetpolicy.com the adress and name of property, management company to get this code.',
}

export enum EAnimalInfoContent {
  ANIMAL_AUTHORIZED_STATUS_INFO = 'An animal is authorized after the Property Management has approved your animal and your lease has been started.',
  ANIMAL_VERIFICATION_STATUS_INFO = 'The animal is verified once all documentation has been reviewed and verified as being valid and up to date.',
}
