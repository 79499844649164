import { EUsersUrls } from 'data/urls/users.url';
import { emptySplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${EUsersUrls.USERS}`;

export const usersApi = emptySplitApi('users').injectEndpoints({
  endpoints: build => ({
    resendInvitation: build.mutation<void, { username: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EUsersUrls.RESEND_INVITATION}`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useResendInvitationMutation } = usersApi;
