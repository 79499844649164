import { formattedDate } from 'helpers/utils';
import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  TSADocPacketGetUI,
  TSADocPacketGet,
  TTrainerInfoUI,
  TTrainerInfo,
  TSADocPacketCreateUI,
  TSADocPacketCreate,
  TSALetterUpdateUI,
  TSALetterUpdate,
  TNameUpdateUI,
  TNameUpdate,
  TEmailUpdateUI,
  TEmailUpdate,
  TPhoneUpdateUI,
  TPhoneUpdate,
  TFaxUpdateUI,
  TFaxUpdate,
  TTrainedForUpdateUI,
  TTrainedForUpdate,
  TTrainingStartDateUpdateUI,
  TTrainingStartDateUpdate,
  TTrainingEndDateUpdateUI,
  TTrainingEndDateUpdate,
} from 'data/types/animalTraining.types';
import {
  phoneNumberConverter,
  verificationStatusConverter,
} from './general.converters';

export const trainerInfoConvertor: TAuthConverter<
  TTrainerInfoUI,
  TTrainerInfo,
  TTrainerInfoUI,
  TTrainerInfo
> = {
  toDb: data => ({
    name: data.name,
    emailAddress: data.emailAddress?.trim() || undefined,
    phoneNumber: phoneNumberConverter.toDb(data.phoneNumber) || undefined,
    faxNumber: data.faxNumber?.trim() || undefined,
  }),
  fromDb: data => ({
    name: data.name,
    emailAddress: data.emailAddress,
    phoneNumber: data.phoneNumber,
    faxNumber: data.faxNumber,
  }),
};

export const saLetterUpdateConvertor: TCreateConverter<
  TSALetterUpdateUI,
  TSALetterUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.id,
    newFiles: data.newFiles || [],
    removedLettersId: data.removedLettersId || [],
  }),
};

export const trainerNameUpdateConvertor: TCreateConverter<
  TNameUpdateUI,
  TNameUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    name: data.value || null,
  }),
};

export const trainerEmailUpdateConvertor: TCreateConverter<
  TEmailUpdateUI,
  TEmailUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    email: data.value || null,
  }),
};

export const trainerPhoneUpdateConvertor: TCreateConverter<
  TPhoneUpdateUI,
  TPhoneUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    phone: phoneNumberConverter.toDb(data.value),
  }),
};

export const trainerFaxUpdateConvertor: TCreateConverter<
  TFaxUpdateUI,
  TFaxUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    fax: data.value || null,
  }),
};

export const trainedForUpdateConvertor: TCreateConverter<
  TTrainedForUpdateUI,
  TTrainedForUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    trainedFor: data.value || null,
  }),
};

export const trainingStartDateUpdateConvertor: TCreateConverter<
  TTrainingStartDateUpdateUI,
  TTrainingStartDateUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    trainingStartDate: data.value
      ? formattedDate(data.value, 'YYYY-MM-DD')
      : null,
  }),
};

export const trainingEndDateUpdateConvertor: TCreateConverter<
  TTrainingEndDateUpdateUI,
  TTrainingEndDateUpdate
> = {
  toDb: data => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    trainingEndDate: data.value
      ? formattedDate(data.value, 'YYYY-MM-DD')
      : null,
  }),
};

export const getSADocPacketConvertor: TFetchConverter<
  TSADocPacketGetUI,
  TSADocPacketGet
> = {
  fromDb: data => ({
    id: data.id,
    trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
    saLetters: data.saLetters,
    trainedFor: data.trainedFor,
    startTime: data.trainingStartDate,
    endTime: data.trainingEndDate,
    canEdit: data.modifiable,
    ...(data.verificationStatus && {
      status: verificationStatusConverter.fromDb(data.verificationStatus),
    }),
  }),
};

export const createSADocPacketsConvertor: TCreateConverter<
  TSADocPacketCreateUI,
  TSADocPacketCreate
> = {
  toDb: data => {
    const trainerInfo = {
      name: data.name,
      emailAddress: data.emailAddress,
      faxNumber: data.faxNumber,
      phoneNumber: data.phoneNumber,
    };

    return {
      animalId: data.animalId,
      trainerInfo: trainerInfoConvertor.toDb(trainerInfo),
      saLetters: data.saLetters,
      trainedFor: data.trainedFor || undefined,
      trainingStartDate: data.startTime
        ? formattedDate(data.startTime, 'YYYY-MM-DD')
        : undefined,
      trainingEndDate: data.endTime
        ? formattedDate(data.endTime, 'YYYY-MM-DD')
        : undefined,
    };
  },
};
