import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import { formattedDate } from 'helpers/utils';
import {
  EAnimalResidencyStatusDB,
  EAnimalResidencyStatusUI,
  EResidencyStatusDB,
  EResidencyStatusUI,
  TAnimalResidencyDataDB,
  TAnimalResidencyDataUI,
  TCompleteTenantResidencyDB,
  TCompleteTenantResidencyUI,
  TCreateTenantResidencyDB,
  TCreateTenantResidencyUI,
  TResidencyData,
  TResidencyDataUI,
} from 'data/types/residency.types';
import { animalTypesConvertor } from './animal.convertors';

export const animalResidencyStatusConverter: TConvertor<
  EAnimalResidencyStatusUI,
  EAnimalResidencyStatusDB
> = {
  fromDb: data => {
    const dataMapper: {
      [key in EAnimalResidencyStatusDB]: EAnimalResidencyStatusUI;
    } = {
      [EAnimalResidencyStatusDB.ACTIVE]: EAnimalResidencyStatusUI.ACTIVE,
      [EAnimalResidencyStatusDB.TERMINATED]:
        EAnimalResidencyStatusUI.TERMINATED,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: {
      [key in EAnimalResidencyStatusUI]: EAnimalResidencyStatusDB;
    } = {
      [EAnimalResidencyStatusUI.ACTIVE]: EAnimalResidencyStatusDB.ACTIVE,
      [EAnimalResidencyStatusUI.TERMINATED]:
        EAnimalResidencyStatusDB.TERMINATED,
    };
    return dataMapper[data];
  },
};

export const animalResidenciesConverter: TFetchConverter<
  TAnimalResidencyDataUI,
  TAnimalResidencyDataDB
> = {
  fromDb: data => {
    return {
      id: data.animalId,
      name: data.name,
      type: animalTypesConvertor.fromDb(data.type),
      status: animalResidencyStatusConverter.fromDb(data.status),
    };
  },
};
export const residencyDataConverter: TFetchConverter<
  TResidencyDataUI,
  TResidencyData
> = {
  fromDb: data => {
    return {
      id: data.id,
      leaseToDate: formattedDate(data.leaseToDate),
      moveInDate: formattedDate(data.moveInDate),
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      hasAnimal:
        typeof data.hasAnimal === 'boolean' ? data.hasAnimal : undefined,
      animalResidencies: (data.animals || []).map(
        animalResidenciesConverter.fromDb,
      ),
    };
  },
};

export const tenantResidencyCreateConverter: TCreateConverter<
  TCreateTenantResidencyUI,
  TCreateTenantResidencyDB
> = {
  toDb: data => {
    return {
      leasePacketId: data.leasePacketId,
      leaseTo: formattedDate(data.endTime, 'YYYY-MM-DD'),
      moveIn: formattedDate(data.startTime, 'YYYY-MM-DD'),
      propertyName: data.propertyName.label,
      unitName: data.unit.label,
      hasAnimal: data.haveAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      animalIds: data.haveAnimal
        ? (data.animals || []).map(animal => animal.id)
        : [],
    };
  },
};

export const tenantResidencyCompleteConverter: TCreateConverter<
  TCompleteTenantResidencyUI,
  TCompleteTenantResidencyDB
> = {
  toDb: data => {
    return {
      residenceId: data.residencyId,
      hasAnimal: data.haveAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      animalIds: data.haveAnimal
        ? (data.animals || []).map(animal => animal.id)
        : [],
    };
  },
};

export const tenantResidencyStatusConverter: TConvertor<
  EResidencyStatusUI,
  EResidencyStatusDB
> = {
  fromDb: data => {
    const dataMapper: { [key in EResidencyStatusDB]: EResidencyStatusUI } = {
      [EResidencyStatusDB.FORMER_RESIDENT]: EResidencyStatusUI.FORMER_RESIDENT,
      [EResidencyStatusDB.RESIDENT]: EResidencyStatusUI.RESIDENT,
      [EResidencyStatusDB.APPLICANT]: EResidencyStatusUI.APPLICANT,
      [EResidencyStatusDB.DENIED]: EResidencyStatusUI.DENIED,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in EResidencyStatusUI]: EResidencyStatusDB } = {
      [EResidencyStatusUI.FORMER_RESIDENT]: EResidencyStatusDB.FORMER_RESIDENT,
      [EResidencyStatusUI.RESIDENT]: EResidencyStatusDB.RESIDENT,
      [EResidencyStatusUI.APPLICANT]: EResidencyStatusDB.APPLICANT,
      [EResidencyStatusUI.DENIED]: EResidencyStatusDB.DENIED,
    };
    return dataMapper[data];
  },
};
