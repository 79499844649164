import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { RoutesPaths } from 'routes/Routes.types';
import { TUserSliceState } from '../store.types';

const getBaseUrl = (forAdmin?: boolean) =>
  `${process.env.REACT_APP_HOSTNAME}/${!forAdmin ? 'tenant' : 'admin'}/api/v2`;

export const authSplitApi = (
  reducerPath: string,
  tagTypes?: string[],
  forAdmin?: boolean,
) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: getBaseUrl(forAdmin),
      prepareHeaders: async (headers, { getState }) => {
        let session = null;
        try {
          session = await Auth.currentSession();
        } catch (err) {
          window.location.replace(`/${RoutesPaths.LOGIN}`);
          localStorage.clear();
        }
        const tenantId = (getState() as { userSlice: TUserSliceState })
          .userSlice.impersonateByTenant;
        if (session) {
          headers.set(
            'authorization',
            `Bearer ${session.getIdToken().getJwtToken()}`,
          );
          if (tenantId) {
            headers.set('x-user-id', tenantId);
          } else {
            headers.delete('x-user-id');
          }
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: getBaseUrl(false),
    }),
    endpoints: () => ({}),
  });
