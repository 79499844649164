import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'redux/store';

export const ImpersonateUpdatesLayer: FC = ({ children }) => {
  const { impersonateByTenant } = useAppSelector(state => state.userSlice);

  useEffect(() => {
    window.addEventListener('storage', e => {
      if (e.key === 'tenantId' && e.newValue !== impersonateByTenant) {
        const href = `${window.location.origin}/impersonation/${e.newValue}`;
        window.open(href, '_self', '');
      }
    });
  }, []);

  return <>{children}</>;
};

export default ImpersonateUpdatesLayer;
