import moment, { Moment } from 'moment';
import 'moment-precise-range-plugin';
import { Location, Params } from 'react-router-dom';

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
            .mx-${i} {
                margin: 0 ${n}px !important;
            }
            .my-${i} {
                margin: ${n}px 0 !important;
            }
            .m-${i} {
                margin: ${n}px !important;
            }
            .mt-${i} {
                margin-top: ${n}px !important;
            }
            .mb-${i} {
                margin-bottom: ${n}px !important;
            }
            .ml-${i} {
                margin-left: ${n}px !important;
            }
            .mr-${i} {
                margin-right: ${n}px !important;
            }
            .pt-${i} {
                padding-top: ${n}px !important;
            }
            .pb-${i} {
                padding-bottom: ${n}px !important;
            }
            .pl-${i} {
                padding-left: ${n}px !important;
            }
            .pr-${i} {
                padding-right: ${n}px !important;
            }
            .px-${i} {
                padding: 0 ${n}px !important;
            }
            .py-${i} {
                padding: ${n}px 0 !important;
            }
            .p-${i} {
                padding: ${n}px !important;
            }
        `;
  });

  return classNames;
};

export const defaultDateFormat = 'MM/DD/YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:mm';

export const regExps = {
  phoneNumber: /^\+?[1-9]\d{7,14}$/,
};

export const verificationCodeLength = 6;

export const formattedDate = (date?: Moment | string, format?: string) => {
  return moment(date).format(format || defaultDateFormat);
};

export const getRoutePath = (location: Location, params: Params): string => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export const getDuration = (duration: number) => {
  if (duration / 60 > 1) {
    const minutes = duration - Math.trunc(duration / 60) * 60;
    return `${Math.trunc(duration / 60)}h ${
      minutes > 0 ? `${minutes}min` : ''
    }`;
  }

  return `${duration} min`;
};

export const getReadableDuration = (
  startDate: number | string | Moment,
  endDate: number | string | Moment,
) => {
  const start = moment(startDate);
  const end = moment(endDate);

  return moment.preciseDiff(start, end);
};
