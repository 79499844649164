import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EResidencyUrls } from 'data/urls/residency.url';
import {
  TCompleteTenantResidencyUI,
  TCreateTenantResidencyUI,
  TResidencyData,
  TResidencyDataUI,
} from 'data/types/residency.types';
import {
  residencyDataConverter,
  tenantResidencyCompleteConverter,
  tenantResidencyCreateConverter,
} from 'data/convertors/residency.convertor';
import { TMoveOutFromResidencyFormUI } from 'data/types/animal.types';
import { EAnimalUrls } from 'data/urls/animal.url';
import { animalMoveOutFromResidenciesConvertor } from 'data/convertors/animal.convertors';

const baseUrl = `${EResidencyUrls.RESIDENCY}`;

export const residencyApi = authSplitApi('residency', [
  'residency',
  'inCompleteResidency',
]).injectEndpoints({
  endpoints: build => ({
    getResidencyData: build.query<TResidencyDataUI[], void>({
      query() {
        return {
          url: baseUrl,
          method: 'GET',
        };
      },
      providesTags: ['residency'],
      transformResponse: (data: TResidencyData[]) =>
        data.map(item => residencyDataConverter.fromDb(item)),
    }),
    getIncompleteResidencyData: build.query<TResidencyDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EResidencyUrls.INCOMPLETE}`,
          method: 'GET',
        };
      },
      providesTags: ['inCompleteResidency'],
      transformResponse: (data: TResidencyData[]) =>
        data.map(item => residencyDataConverter.fromDb(item)),
    }),
    createTenantResidency: build.mutation<void, TCreateTenantResidencyUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.APPLICATION}`,
          method: 'POST',
          body: tenantResidencyCreateConverter.toDb(data),
        };
      },
    }),
    completeTenantResidency: build.mutation<void, TCompleteTenantResidencyUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.COMPLETE}`,
          method: 'PATCH',
          body: tenantResidencyCompleteConverter.toDb(data),
        };
      },
    }),
    moveOutAnimalsFromResidency: build.mutation<
      void,
      TMoveOutFromResidencyFormUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EAnimalUrls.ANIMALS}/${EAnimalUrls.MOVE_OUT}`,
          method: 'PATCH',
          body: animalMoveOutFromResidenciesConvertor.toDb(body),
        };
      },
      invalidatesTags: ['inCompleteResidency'],
    }),
  }),
});

export const {
  useGetResidencyDataQuery,
  useCreateTenantResidencyMutation,
  useCompleteTenantResidencyMutation,
  useGetIncompleteResidencyDataQuery,
  useMoveOutAnimalsFromResidencyMutation,
} = residencyApi;
