import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TAppSliceState = {
  isUploadLoading: boolean;
  cardholder: string;
  redirectLink: string;
  showServiceWorkerToast: boolean;
  showUpdateModal: boolean;
};

const initialState: TAppSliceState = {
  isUploadLoading: false,
  cardholder: '',
  redirectLink: '',
  showServiceWorkerToast: false,
  showUpdateModal: false,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setIsUploadLoading(state, action: PayloadAction<boolean>) {
      state.isUploadLoading = action.payload;
    },
    setCardholder(state, action: PayloadAction<string>) {
      state.cardholder = action.payload;
    },
    setRedirectLink(state, action: PayloadAction<string>) {
      state.redirectLink = action.payload;
    },
    setShowServiceWorkerToast(state, action: PayloadAction<boolean>) {
      state.showServiceWorkerToast = action.payload;
    },
    setShowUpdateModal(state, action: PayloadAction<boolean>) {
      state.showUpdateModal = action.payload;
    },
  },
});

export default appSlice;
