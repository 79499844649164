import { useEffect, useState } from 'react';
import moment from 'moment';
import Form, { FormItem } from 'components/Base/Form';
import { Col, Row } from 'components/Base/Grid';
import { TextField } from 'components/Base/TextField';
import { DatePicker } from 'components/Base/DatePicker';
import { RadioGroup, Radio } from 'components/Base/Radio';
import ImageUploader from 'components/Base/ImageUploader';
import { Paragraph } from 'components/Base/Typography';
import RadioGroupSwitcher from 'components/Base/RadioGroupSwitcher';

import {
  useGetAnimalBreedsQuery,
  useGetAnimalColorsQuery,
  useGetAnimalSpeciesQuery,
} from 'redux/apiSlice/animal.slice';
import Select from 'components/Base/Select';
import { TOptionTypeUi } from 'data/types/generalTypes';
import { yesNoOptions } from 'data/datas/genericData';
import {
  animalFormFields,
  genderOptions,
  initialValues,
  typeDropdownItems,
} from './AnimalForm.data';
import { TAnimalFormProps } from './AnimalForm.types';

const {
  profilePicture,
  name,
  type,
  birthDate,
  species,
  breed,
  primaryColor,
  secondaryColor1,
  secondaryColor2,
  weight,
  gender,
  neutered,
  houseTrained,
} = animalFormFields;

const AnimalForm = ({ animalData, formObj, actionType }: TAnimalFormProps) => {
  const { form } = formObj;
  const { setFieldsValue } = form;
  const [animalGender, setAnimalGender] = useState('');
  const [animalType, setAnimalType] = useState<TOptionTypeUi | null>(null);

  const { data: animalSpecies } = useGetAnimalSpeciesQuery();
  const { data: animalBreeds, isFetching: breedsLoading } =
    useGetAnimalBreedsQuery(
      {
        speciesId: animalType?.__isNew__
          ? undefined
          : animalType?.value || undefined,
      },
      { skip: !animalType?.value || animalType?.__isNew__ },
    );
  const { data: animalColors } = useGetAnimalColorsQuery();

  const handleSpeciesChange = (item: TOptionTypeUi) => {
    setFieldsValue({ [breed.name]: null });
    setAnimalType(item);
  };

  useEffect(() => {
    if (!animalData) {
      return;
    }
    setAnimalType(animalData.species || null);
  }, [animalData]);

  return (
    <Form
      form={form}
      name="animal"
      autoComplete="off"
      requiredMark={false}
      initialValues={animalData || initialValues}
      id="create-animal"
      scrollToFirstError
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} md={12}>
          <FormItem name={profilePicture.name} className="mb-2">
            <ImageUploader
              url={animalData?.profilePicture || ''}
              width={80}
              height={80}
            />
          </FormItem>
        </Col>
      </Row>
      {actionType === 'complete' && (
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12}>
            <FormItem {...type}>
              <Select options={typeDropdownItems} isDisabled />
            </FormItem>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 0]}>
        <Col xs={24} md={12}>
          <FormItem name={name.name} label={name.label} rules={name.rules}>
            <TextField placeholder={name.placeholder} />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name={birthDate.name}
            label={birthDate.label}
            rules={birthDate.rules}
          >
            <DatePicker
              placeholder={birthDate.placeholder}
              disabledDate={date => date > moment()}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            name={species.name}
            label={species.label}
            rules={species.rules}
          >
            <Select
              isCreatable
              isClearable
              options={animalSpecies || []}
              onChange={item => {
                const newItem = item as unknown as TOptionTypeUi;
                handleSpeciesChange(newItem);
              }}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem name={breed.name} label={breed.label}>
            <Select
              isCreatable
              isClearable
              options={
                animalType && !animalType?.__isNew__ ? animalBreeds || [] : []
              }
              placeholder={breed.placeholder}
              isLoading={breedsLoading}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24} md={8}>
          <FormItem
            name={primaryColor.name}
            label={primaryColor.label}
            rules={primaryColor.rules}
          >
            <Select
              isCreatable
              isClearable
              options={animalColors || []}
              placeholder={primaryColor.placeholder}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem name={secondaryColor1.name} label={secondaryColor1.label}>
            <Select
              isClearable
              options={animalColors || []}
              placeholder={secondaryColor1.placeholder}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem name={secondaryColor2.name} label={secondaryColor2.label}>
            <Select
              isClearable
              options={animalColors || []}
              placeholder={secondaryColor2.placeholder}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>
          <FormItem {...weight}>
            <TextField placeholder={weight.placeholder} type="number" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24}>
          <Row align="bottom">
            <FormItem
              name={gender.name}
              label={gender.label}
              rules={gender.rules}
              className="mr-6"
            >
              <RadioGroupSwitcher
                options={genderOptions}
                onChange={e => setAnimalGender(e.target.value)}
              />
            </FormItem>
            <Row align="middle">
              <Paragraph fontLevel={5} className="mb-0 mr-2 mb-6">
                {`Is your animal ${
                  animalGender === 'female' ? 'Spayed' : 'Neutered'
                }?`}
              </Paragraph>
              <FormItem name={neutered.name} rules={neutered.rules}>
                <RadioGroup>
                  {yesNoOptions?.map(item => (
                    <Radio
                      value={item.value}
                      label={item.label}
                      key={item.value}
                    />
                  ))}
                </RadioGroup>
              </FormItem>
            </Row>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24}>
          <Row align="middle">
            <Paragraph fontLevel={5} className="mb-0 mr-2 mb-6">
              Is your animal house trained ?
            </Paragraph>
            <FormItem name={houseTrained.name} rules={houseTrained.rules}>
              <RadioGroup>
                {yesNoOptions?.map(item => (
                  <Radio
                    value={item.value}
                    label={item.label}
                    key={item.value}
                  />
                ))}
              </RadioGroup>
            </FormItem>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default AnimalForm;
