import {
  TProfileDetails,
  TProfileDetailsUI,
  TProfilePictureUpdateUI,
  TNameUpdateUI,
  TEmailUpdateUI,
  TPhoneUpdateUI,
  TApplicationProfile,
  TApplicationProfileUI,
  TEsaMetadataUpdateUI,
  TSaMetadataUpdateUI,
  TTenantPropertyDB,
  TTenantProperty,
  TRoommateHasAnimalUi,
  TTenantCreateUI,
  TTenantDataUI,
  TTenantDataFromDb,
  TOwnerHasAnimalUi,
} from 'data/types/tenant.types';
import { ETenantUrls } from 'data/urls/tenant.url';
import { EApplicationUrls } from 'data/urls/application.url';
import {
  createTenantProfileConvertor,
  getTenantConvertor,
  getTenantProfileConvertor,
  tenantProfilePictureUpdateConverter,
  tenantNameUpdateConverter,
  tenantEmailUpdateConvertor,
  tenantPhoneUpdateConvertor,
  applicationProfileConvertor,
  esaMetadataUpdateConvertor,
  saMetadataUpdateConvertor,
  onboardTenantConvertor,
  getTenantPropertiesConvertor,
  roommateHasAnimalUpdateConverter,
  ownerHasAnimalUpdateConverter,
} from 'data/convertors/tenant.convertors';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${ETenantUrls.TENANT_PROFILE}`;

export const tenantApi = authSplitApi('tenant', [
  'tenant',
  'details',
  'profilePicture',
  'name',
  'email',
  'phone',
  'applicationProfile',
]).injectEndpoints({
  endpoints: build => ({
    getTenantProfile: build.query<TTenantDataUI, string | null>({
      query() {
        return {
          url: `${baseUrl}`,
          method: 'GET',
        };
      },
      providesTags: ['tenant'],
      transformResponse: (data: TTenantDataFromDb) => {
        if (!data) {
          return null;
        }
        return getTenantProfileConvertor.fromDb(data);
      },
    }),
    getProfileDetails: build.query<TProfileDetailsUI, null | void>({
      query() {
        return {
          url: `${baseUrl}/${ETenantUrls.DETAILS}`,
          method: 'GET',
        };
      },
      providesTags: ['details'],
      transformResponse: (data: TProfileDetails) => {
        return getTenantConvertor.fromDb(data);
      },
    }),
    getTenantProperties: build.query<TTenantProperty[], void>({
      query() {
        return {
          url: `${baseUrl}/${ETenantUrls.PROPERTIES}`,
          method: 'GET',
        };
      },
      providesTags: ['properties'],
      transformResponse: (data: TTenantPropertyDB[]) => {
        return getTenantPropertiesConvertor.fromDb(data);
      },
    }),
    getApplicationProfile: build.query<
      TApplicationProfileUI,
      { animalId?: string }
    >({
      query({ animalId }) {
        return {
          url: `${EApplicationUrls.APPLICATION}/${EApplicationUrls.STEP}/${EApplicationUrls.TENANT}`,
          method: 'GET',
          params: { animalId },
        };
      },
      transformResponse: (data: TApplicationProfile) =>
        applicationProfileConvertor.fromDb(data),
      providesTags: ['applicationProfile'],
    }),
    updateProfilePicture: build.mutation<void, TProfilePictureUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${ETenantUrls.PROFILE_PICTURE}`,
          method: 'PATCH',
          body: tenantProfilePictureUpdateConverter.toDb(data),
        };
      },
      invalidatesTags: ['tenant', 'details', 'applicationProfile'],
    }),
    updateName: build.mutation<void, TNameUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${ETenantUrls.NAME}`,
          method: 'PATCH',
          body: tenantNameUpdateConverter.toDb(data),
        };
      },
      invalidatesTags: ['details', 'applicationProfile'],
    }),
    updateRoommateHasAnimal: build.mutation<void, TRoommateHasAnimalUi>({
      query(data) {
        return {
          url: `${baseUrl}/${ETenantUrls.ROOMMATE_HAS_ANIMAL}`,
          method: 'PATCH',
          body: roommateHasAnimalUpdateConverter.toDb(data),
        };
      },
      invalidatesTags: ['tenant', 'details', 'applicationProfile'],
    }),
    updateOwnerHasAnimal: build.mutation<void, TOwnerHasAnimalUi>({
      query(data) {
        return {
          url: `${baseUrl}/${ETenantUrls.OWNER_HAS_ANIMAL}`,
          method: 'PATCH',
          body: ownerHasAnimalUpdateConverter.toDb(data),
        };
      },
      invalidatesTags: ['tenant', 'details', 'applicationProfile'],
    }),
    updateEmail: build.mutation<void, TEmailUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${ETenantUrls.EMAIL}`,
          method: 'PATCH',
          body: tenantEmailUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['details', 'applicationProfile'],
    }),
    updatePhone: build.mutation<void, TPhoneUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${ETenantUrls.PHONE}`,
          method: 'PATCH',
          body: tenantPhoneUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['details', 'applicationProfile'],
    }),
    createTenantProfile: build.mutation<void, TTenantCreateUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body: createTenantProfileConvertor.toDb(body),
        };
      },
      invalidatesTags: ['tenant'],
    }),
    onboardTenant: build.mutation<void, TTenantCreateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${ETenantUrls.ONBOARD}`,
          method: 'PATCH',
          body: onboardTenantConvertor.toDb(body),
        };
      },
      invalidatesTags: ['tenant'],
    }),
    updateEsaMetadata: build.mutation<void, TEsaMetadataUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${ETenantUrls.ANIMAL_METADATA}/${ETenantUrls.ESA}`,
          method: 'PATCH',
          body: esaMetadataUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['applicationProfile'],
    }),
    updateSaMetadata: build.mutation<void, TSaMetadataUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${ETenantUrls.ANIMAL_METADATA}/${ETenantUrls.SA}`,
          method: 'PATCH',
          body: saMetadataUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['applicationProfile'],
    }),
    getShowQRScan: build.query<boolean, null>({
      query() {
        return {
          url: `${baseUrl}/${ETenantUrls.SHOW_QR_SCAN}`,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTenantProfileQuery,
  useGetTenantPropertiesQuery,
  useCreateTenantProfileMutation,
  useOnboardTenantMutation,
  useGetProfileDetailsQuery,
  useGetApplicationProfileQuery,
  useGetShowQRScanQuery,
  useUpdateRoommateHasAnimalMutation,
  useUpdateOwnerHasAnimalMutation,
  useUpdateProfilePictureMutation,
  useUpdateNameMutation,
  useUpdateEmailMutation,
  useUpdatePhoneMutation,
  useUpdateEsaMetadataMutation,
  useUpdateSaMetadataMutation,
} = tenantApi;
