import moment from 'moment';
import { TFile } from './storage.types';
import {
  TSADocPacketCreate,
  TSADocPacketGet,
  TSADocPacketGetUI,
  TSAPacketForm,
} from './animalTraining.types';
import {
  EGender,
  EGenderUi,
  EValueEditingStates,
  TOptionType,
  TOptionTypeUi,
  TEditingBooleanField,
  TEditingField,
  TEditingFieldUi,
  TExpirationDateType,
  TVetRecordType,
  TFileDataType,
  TVetRecordTypeUI,
  TFailUI,
  TFail,
  TEditingYesNoFieldUi,
  TYesNoOptionType,
  EVerificationStatus,
  EVerificationStatusUi,
  EApplicationStatuses,
  EApplicationStatusesUi,
  TCustomOptionTypeUi,
  TCustomSpeciesOptionTypeUi,
  EReproductive,
  EAnimalStatusesUi,
  EAnimalStatuses,
} from './generalTypes';
import {
  EHPDocPacketTypes,
  EHPDocPacketTypesUI,
  EHPVisitationUI,
  TDocPacketGet,
  TDocPacketGetUI,
  THPContactInfo,
  THPVisitation,
} from './docPacket.types';

export enum EAnimalTypesUI {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export enum EAnimalTypes {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export enum EAuthorizationStatusUI {
  AUTHORIZED = 'Authorized',
  UN_AUTHORIZED = 'Unauthorized',
  NO_STATUS = 'No status',
}
export enum EAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  UN_AUTHORIZED = 'UN_AUTHORIZED',
  NO_STATUS = 'NO_STATUS',
}

export type TOptionTypeUI = {
  key: string;
  id: string;
  value: string;
};

export type TAnimalDetailObjectProps = {
  value: string;
  predefined: boolean;
};

export type TAnimalWeightProps = {
  value: number;
  unit: string;
};

type TUnAuthorizedResidenciesProps = {
  propertyManagementName: string;
  propertyName: string;
};

type TAuthorization = {
  status?: EAuthorizationStatus;
  unAuthorizedResidencies?: TUnAuthorizedResidenciesProps[];
};

export type TAnimalApplicationData = {
  id: string;
  creationTime: string;
  leaseToDate: string | null;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  status: EApplicationStatuses;
  unitNumber: string;
  rejectReason: string;
  complete: boolean;
};

export type TAnimalApplicationDataUI = {
  id: string;
  creationTime: string;
  leaseToDate: string | null;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  status: EApplicationStatusesUi;
  unitNumber: string;
  rejectReason: string;
  complete: boolean;
};

export type TBlockedAction = {
  animalAction: EAnimalActionDB;
  reason: string;
};

export type TBlockedActionUI = {
  animalAction: EAnimalActionUI;
  reason: string;
};

export type TAnimalPostUI = {
  applications: TAnimalApplicationDataUI[];
  id: string;
  gender: EGenderUi;
  reproduction: EReproductive | null;
  authorizationStatus?: EAuthorizationStatusUI;
  complete: boolean;
  name?: string;
  profilePicture?: string;
  status?: EAnimalStatusesUi;
  type?: string;
  species?: string;
  breed?: string;
  weight?: number;
  actions?: EAnimalActionUI[];
};

export type TAnimalGet = {
  applications: TAnimalApplicationData[];
  id: string;
  gender: EGender;
  isReproductive: boolean | null;
  authorization?: TAuthorization;
  complete: boolean;
  name?: string;
  profilePicture?: string;
  type?: string;
  status?: EAnimalStatuses;
  species?: TAnimalDetailObjectProps;
  breed?: TAnimalDetailObjectProps;
  weight?: TAnimalWeightProps;
  actions?: EAnimalActionDB[];
};

export type TGenderUI = { gender: EGenderUi; neutered: TYesNoOptionType };
export type TGender = { type: EGender; isReproductive?: boolean | null };

export type TAnimalFormDataUI = {
  name: string;
  birthDate: moment.Moment;
  breed: TOptionTypeUi;
  species: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  weight: string;
  profilePicture: TFile | null;
  gender: EGenderUi;
  neutered: TYesNoOptionType;
  houseTrained: TYesNoOptionType;
  animalType: EAnimalTypesUI;
};

export type TAnimalFormData = {
  animalType: EAnimalTypes;
  name: string;
  birthDate: string;
  species: TOptionType;
  breed?: TOptionType | null;
  primaryColor: TOptionType;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  weight: string | null;
  profilePicture?: string | null;
  gender: TGender;
  houseTrained?: boolean | null;
};
export type TCreateAnimalIncompleteUI = {
  name: string;
  type: { value: EAnimalTypesUI; label: EAnimalTypesUI };
};

export type TAnimalIncompleteUI = {
  name: string;
  type: EAnimalTypesUI;
  id: string;
};

export type TAnimalUI = {
  name: string;
  type: EAnimalTypesUI;
  id: string;
  status: EAnimalStatusesUi;
};

export type TCreateAnimalIncompleteDB = {
  name: string;
  type: EAnimalTypes;
};
export type TBreedType = TOptionType | TCustomOptionTypeUi;
export type TBreedTypeUi = TOptionTypeUi;

export type TCompleteAnimalFormDataUI = {
  name: string;
  type: TOptionTypeUi;
  birthDate: moment.Moment;
  breed: TBreedTypeUi | null;
  species: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  weight: string;
  profilePicture: TFile | string | null;
  gender: EGenderUi;
  neutered: TYesNoOptionType;
  houseTrained: TYesNoOptionType;
  animalId: string;
};

export type TCompleteAnimalFormData = {
  animalId: string;
  animalType: TOptionTypeUi;
  name: string;
  birthDate: string;
  species: TOptionType | TCustomSpeciesOptionTypeUi;
  breed: TOptionType | TCustomOptionTypeUi | null;
  primaryColor: TOptionType | TCustomOptionTypeUi;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  weight: string | null;
  profilePicture?: string | null;
  gender: TGender;
  houseTrained?: boolean | null;
};

export type TAnimalVetRecordsListUi = {
  documentFiles: TFileDataType[];
  expiration: TExpirationDateType;
  id: string;
  locked: boolean;
  isExpired: boolean;
  type: TVetRecordType;
  status: EVerificationStatusUi;
};

export type TAnimalVetRecordsList = {
  documentFiles: TFileDataType[];
  expiration: TExpirationDateType;
  id: string;
  locked: boolean;
  IsExpired: boolean;
  type: TVetRecordType;
  verificationStatus: EVerificationStatus;
};

export type TAnimalVetRecordCreateUi = {
  animalId: string;
  expirationDate: string;
  files: TFile[];
  docName: string;
  type: string;
};

export type TAnimalVetRecordCreate = {
  animalId: string;
  expiration: TExpirationDateType;
  files: TFile[];
  type: TVetRecordTypeUI;
};

export type TConfirmPaymentUi = {
  animalId: string;
  leasePacketId: string;
};

export type TConfirmPayment = TConfirmPaymentUi;

export type TVetRecordTypeNameUpdate = {
  animalId: string;
  vetRecordId: string;
  type: TVetRecordTypeUI;
};

export type TVetRecordTypeNameUpdateUI = {
  animalId: string;
  vetRecordId: string;
  type: string;
};

export type TVetRecordExpirationUpdate = {
  animalId: string;
  vetRecordId: string;
  expiration: TExpirationDateType;
};

export type TVetRecordExpirationUpdateUI = {
  animalId: string;
  vetRecordId: string;
  expirationDate: string;
};

export type TConvertAnimalToPetUI = {
  animalId: string;
};

export type TConvertAnimalToPet = {
  animalId: string;
};

export type TConvertAnimalToEsaUI = {
  animalId: string;
  hpDocType?: EHPDocPacketTypesUI;
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string[];
  hpVisitation?: EHPVisitationUI;
  hpDocPacketLetter?: TFile[];
};

export type TConvertAnimalToEsa = {
  animalId: string;
  hpContactInfo?: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
};

export type TConvertAnimalToSaUI = TSAPacketForm & {
  animalId: string;
  hpDocType?: EHPDocPacketTypesUI;
  hpDocFormName?: string;
  hpDocFormEmailAddress?: string;
  hpDocFormPhoneNumber?: string;
  hpDocFormFaxNumber?: string;
  hpDocFormWebsites?: string[];
  hpVisitation?: EHPVisitationUI;
  hpDocPacketLetter?: TFile[];
};

export type TConvertAnimalToSa = TSADocPacketCreate & {
  animalId: string;
  hpContactInfo?: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
};

export type TUpdateAnimalConvHPDocsUI = {
  animalId: string;
  conversionId: string;
  hpDocType?: EHPDocPacketTypesUI;
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string[];
  hpVisitation?: EHPVisitationUI;
  hpDocPacketLetter?: TFile[];
};

export type TUpdateAnimalConvHPDocs = {
  animalId: string;
  conversionId: string;
  hpDocumentPacketType?: EHPDocPacketTypes;
  hpContactInfo?: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
};

export type TVetRecordDocumentsUI = {
  animalId: string;
  vetRecordId: string;
  docs?: TFileDataType[];
  removedDocumentsId?: string[];
};

export type TVetRecordDocuments = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFile[];
  removedDocumentsId: string[];
};

export type TAnimalType = {
  animalId: string;
  newType: EAnimalTypes;
};

export type TAnimalTypeUI = {
  animalId: string;
  type: EAnimalTypesUI;
};

export type TVetRecordUI = {
  animalId: string;
  vetRecordId: string;
};

export type TVetRecord = {
  animalId: string;
  vetRecordId: string;
};

export type TEditingWeightField = {
  value?: number | null;
  unit?: string | null;
  allowedOperations: EValueEditingStates[];
};

export type TEditingWeightFieldUi = {
  value: string | null;
  unit: string | null;
  canEdit: boolean;
  canRemove: boolean;
};
export type TAnimalDetailsGenderField = {
  value: EGender;
  allowedOperations: EValueEditingStates[];
};
export type TAnimalDetailsGenderFieldUi = {
  value: EGenderUi;
  canEdit: boolean;
  canRemove: boolean;
};

export enum EAnimalActionDB {
  DELETE = 'DELETE',
  MOVE_OUT = 'MOVE_OUT',
  CONVERT = 'CONVERT',
}

export enum EAnimalActionUI {
  DELETE = 'DELETE',
  MOVE_OUT = 'MOVE_OUT',
  CONVERT = 'CONVERT',
}

export enum EConvertAnimalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum EConvertAnimalStatusUI {
  APPROVED = 'Converted',
  REJECTED = 'Not approved',
  PENDING = 'Pending',
}

export type TAnimalTypeConversion = {
  createdAt: string;
  fromType: EAnimalTypes;
  hpDocumentPacket: TDocPacketGet | null;
  saDocumentPacket: TApplicationSADocPacketGet | null;
  id: string;
  reason: string | null;
  status: EConvertAnimalStatus;
  toType: EAnimalTypes;
};

export type TAnimalTypeConversionUi = {
  createdAt: string;
  fromType: EAnimalTypesUI;
  hpDocumentPacket: TDocPacketGetUI | null;
  saDocumentPacket: TApplicationSADocPacketGetUI | null;
  id: string;
  reason: string | null;
  status: EConvertAnimalStatusUI;
  toType: EAnimalTypesUI;
};

export type TAnimalDetails = {
  id: string;
  name: TEditingField;
  profilePicture: TEditingField;
  species: TEditingField;
  breed: TEditingField;
  conversions?: TAnimalTypeConversion[] | null;
  primaryColor: TEditingField;
  secondaryColor1: TEditingField;
  secondaryColor2: TEditingField;
  birthDate: TEditingField;
  weight: TEditingWeightField;
  isHouseTrained: TEditingBooleanField;
  gender: TAnimalDetailsGenderField;
  isReproductive: TEditingBooleanField;
  vetRecords: TAnimalVetRecordsList[] | null;
  saDocumentPackets: TSADocPacketGet[] | null;
  type: EAnimalTypes;
  status?: EAnimalStatuses;
  authorization?: TAuthorization;
  actions?: EAnimalActionDB[];
  blockedActions?: TBlockedAction[];
};

export type TAnimalDetailsUi = {
  id: string;
  name: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  species: TEditingFieldUi;
  breed: TEditingFieldUi;
  conversions?: TAnimalTypeConversionUi[] | null;
  primaryColor: TEditingFieldUi;
  secondaryColor1: TEditingFieldUi;
  secondaryColor2: TEditingFieldUi;
  birthDate: TEditingFieldUi;
  weight: TEditingWeightFieldUi;
  isHouseTrained: TEditingYesNoFieldUi;
  gender: TAnimalDetailsGenderFieldUi;
  isReproductive: TEditingYesNoFieldUi;
  vetRecordList: TAnimalVetRecordsListUi[];
  packets: TSADocPacketGetUI[];
  type: EAnimalTypesUI;
  status?: EAnimalStatusesUi;
  authorizationStatus?: EAuthorizationStatusUI;
  actions?: EAnimalActionUI[];
  blockedActions?: TBlockedActionUI[];
};

export type TAnimalDetailsById = {
  name?: TEditingField;
  type?: EAnimalTypes;
  profilePicture?: TEditingField;
  species?: TOptionTypeUi | null;
  breed?: TOptionTypeUi | null;
  primaryColor?: TOptionTypeUi | null;
  secondaryColor1?: TOptionTypeUi | null;
  secondaryColor2?: TOptionTypeUi | null;
  birthDate?: TEditingField;
  weight?: TEditingWeightField;
  isHouseTrained?: TEditingBooleanField;
  gender?: TAnimalDetailsGenderField;
  isReproductive?: TEditingBooleanField;
};

export type TAnimalDetailsByIdUi = {
  name?: string | null;
  type?: TOptionTypeUi | null;
  profilePicture?: string | null;
  species?: TOptionTypeUi | null;
  breed?: TOptionTypeUi | null;
  primaryColor?: TOptionTypeUi | null;
  secondaryColor1?: TOptionTypeUi | null;
  secondaryColor2?: TOptionTypeUi | null;
  birthDate?: moment.Moment | null;
  weight?: number | null;
  houseTrained?: string | null;
  gender?: string;
  neutered?: string | null;
};

export type TAnimalMetadata = {
  description: string | null;
  hasEsaLetterForAnimal: boolean | null;
  trainedForObservableDisability: boolean | null;
  trainedForObservableDisabilityDescription: string | null;
};

export type TAnimalMetadataUi = {
  description: string | null;
  hasEsaLetterForAnimal: TYesNoOptionType;
  trainedForObservableDisability: TYesNoOptionType;
  trainedForObservableDisabilityDescription: string | null;
};

export type TAnimalFieldUpdateUi = {
  id: string;
  value: string;
};

export type TAnimalCreatableFieldUpdateUi = {
  id: string;
  value: TOptionTypeUi;
};

export type TAnimalCreateFieldUpdateUi = {
  id: string;
  value: string;
};

export type TAnimalNameUpdate = {
  animalId: string;
  name: string;
};
export type TAnimalNameUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalHouseTrainedUpdate = {
  animalId: string;
  houseTrained?: boolean | null;
};
export type TAnimalHouseTrainedUpdateUi = {
  id: string;
  value: TYesNoOptionType;
};
export type TAnimalGenderUpdate = {
  animalId: string;
  gender: EGender;
};
export type TAnimalGenderUpdateUi = {
  id: string;
  value: EGenderUi;
};
export type TAnimalProfileUpdate = {
  animalId: string;
  profilePicture: string;
};
export type TAnimalProfileUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalWeightUpdate = {
  animalId: string;
  weight: string | null;
  unit: string;
};
export type TAnimalWeightUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalBirthDate = {
  animalId: string;
  birthDate: string;
};
export type TAnimalBirthDateUi = TAnimalFieldUpdateUi;
export type TAnimalSecondaryColorUpdate = {
  animalId: string;
  colorId: string;
};
export type TAnimalSecondaryColorUpdateUi = TAnimalCreateFieldUpdateUi;
export type TAnimalReproductiveUpdate = {
  animalId: string;
  reproductive?: boolean | null;
};
export type TAnimalReproductiveUpdateUi = {
  id: string;
  value: TYesNoOptionType;
};
export type TAnimalSpeciesUpdate = {
  animalId: string;
  species: TOptionType;
};
export type TAnimalSpeciesUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalPrimaryColorUpdate = {
  animalId: string;
  primaryColor: TOptionType;
};
export type TAnimalPrimaryColorUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalBreedUpdate = {
  animalId: string;
  breed: TOptionType;
};

export type TAnimalBreedUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalSecondaryColor1Update = {
  animalId: string;
  colorId: string;
};

export type TApplicationSADocPacketGetUI = TSADocPacketGetUI & {
  fails: TFailUI[];
};
export type TApplicationSADocPacketGet = TSADocPacketGet & { fails?: TFail };

export type TApplicationVetRecordsUI = TAnimalVetRecordsListUi & {
  fails: TFailUI[];
};
export type TApplicationVetRecords = TAnimalVetRecordsList & {
  fails?: TFail;
};

export type TApplicationAnimalUI = Omit<
  TAnimalDetailsUi,
  'vetRecordList' | 'packets' | 'actions'
> & {
  fails: TFailUI[];
  documentsFails?: TFailUI[];
  vetRecordList: TApplicationVetRecordsUI[];
  packets: TApplicationSADocPacketGetUI[];
};

export type TApplicationAnimal = Omit<
  TAnimalDetails,
  'vetRecords' | 'saDocumentPackets' | 'actions'
> & {
  fails?: TFail;
  vetRecords: TApplicationVetRecords[] | null;
  saDocumentPackets: TApplicationSADocPacketGet[] | null;
};

export enum EMoveOutReasonTypeDB {
  ANIMAL_HAS_PASSED_AWAY = 'ANIMAL_HAS_PASSED_AWAY',
  ANIMAL_HAS_RE_HOMED = 'ANIMAL_HAS_RE_HOMED',
}

export enum EMoveOutReasonTypeUI {
  ANIMAL_HAS_PASSED_AWAY = 'ANIMAL_HAS_PASSED_AWAY',
  ANIMAL_HAS_RE_HOMED = 'ANIMAL_HAS_RE_HOMED',
}

export type TMoveOutReasonDB = {
  predefined?: EMoveOutReasonTypeDB;
  other?: string;
};

export type TMoveOutReasonUI = {
  predefinedType?: EMoveOutReasonTypeUI;
  other?: string;
};

export type TMoveOutFormDB = {
  animalId: string;
  reason: TMoveOutReasonDB;
  deleteAnimal?: boolean;
};

export type TMoveOutFormUI = {
  animalId: string;
  reason: EMoveOutReasonTypeUI;
  other: string;
  deleteAnimal?: boolean;
};

export type TMoveOutFromResidencyFormDB = {
  animalIds: string[];
  residencyId: string;
  moveOutReason: TMoveOutReasonDB;
  deleteAnimal?: boolean;
};

export type TMoveOutFromResidencyFormUI = {
  animalIds: string[];
  residencyId: string;
  reason: EMoveOutReasonTypeUI;
  other: string;
  deleteAnimal?: boolean;
};

export type TUnAuthorizedResidencyDB = {
  propertyName: string;
  propertyManagementName: string;
  unitNumber: string;
};

export type TUnAuthorizedResidencyUI = {
  propertyName: string;
  propertyManagementName: string;
  unitNumber: string;
};

export type TIncompleteAnimalsDataDB = {
  count: number;
  animalId: string;
  applicationId: string;
  incompleteResource: 'ANIMAL' | 'APPLICATION';
};
export type TIncompleteAnimalsDataUI = {
  count: number;
  animalId: string;
  applicationId: string;
  incompleteResource: 'ANIMAL' | 'APPLICATION';
};
