import styled from 'styled-components';
import { PageSpinner, Spinner } from '@landlord-tech/ui-kit-opp';
import { TSpinnerProps, UIKitTSpinnerProps } from './Spinner.type';

export const SSpinner = styled(Spinner)<TSpinnerProps>`
  &&& {
  }
`;
export const SPageSpinner = styled(PageSpinner)<UIKitTSpinnerProps>`
  &&& {
  }
`;
