import {
  TEditingField,
  TEditingFieldUi,
  TFail,
  TFailUI,
  TFileUI,
  TYesNoOptionType,
} from './generalTypes';
import { TDocPacketGet, TDocPacketGetUI } from './docPacket.types';

export type TTenantProperty = {
  propertyId: string;
  name: string;
};

export type TTenantPropertyDB = {
  propertyId: string;
  name: string;
};

export enum ETenantActionDB {
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  COMPLETE_RESIDENCY = 'COMPLETE_RESIDENCY',
  SCAN_QR = 'SCAN_QR',
  NO_ACTION = 'NO_ACTION',
}

export enum ETenantActionUI {
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  COMPLETE_RESIDENCY = 'COMPLETE_RESIDENCY',
  SCAN_QR = 'SCAN_QR',
  NO_ACTION = 'NO_ACTION',
}
export type TTenantDataFromDb = {
  name: string;
  username: string;
  email: string;
  phone: string;
  profilePicture: string | null;
  userId: string;
  requiredAction: ETenantActionDB;
  hasTraining: boolean;
};

export type TTenantDataUI = {
  name: string;
  username: string;
  email: string;
  phone: string;
  profilePicture: string | null;
  userId: string;
  requiredAction: ETenantActionUI;
  hasTraining: boolean;
} | null;

export type TTenantCreateUI = {
  name: string;
  email?: string | null;
  phone?: string | null;
  haveAnimal: boolean;
  roommateHasAnimal: boolean;
  profilePicture?: TFileUI;
};

export type TTenantCreateDB = {
  name: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string;
};

export type TProfileFieldUpdateUI = {
  value: string;
};
export enum ETenantResidencyStatusUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
  DENIED = 'Not Approved',
}
export enum ETenantResidencyStatus {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
  DENIED = 'DENIED',
}
export type TProfilePictureUpdateUI = TProfileFieldUpdateUI;
export type TProfilePictureUpdate = { profilePicture: string | null };

export type TRoommateHasAnimalUi = {
  roommateHasAnimal: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatusUI;
};
export type TRoommateHasAnimal = {
  roommateAnimalStatus: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatus;
};

export type TOwnerHasAnimalUi = {
  hasAnimal: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatusUI;
};
export type TOwnerHasAnimal = {
  animalStatus: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatus;
};

export type TNameUpdateUI = TProfileFieldUpdateUI;
export type TNameUpdate = { name: string };

export type TEmailUpdateUI = TProfileFieldUpdateUI;
export type TEmailUpdate = { email: string };

export type TPhoneUpdateUI = TProfileFieldUpdateUI;
export type TPhoneUpdate = { phone: string | null };

export type TTenantResidencyInfoUI = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  residencyStatus: ETenantResidencyStatusUI;
  rejectReason?: string;
  canEdit: boolean;
};

export type TTenantResidencyInfo = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  status: ETenantResidencyStatus;
  rejectReason?: string;
  editAllowed: boolean;
};
export type TProfileDetailsUI = {
  name: TEditingFieldUi;
  email: TEditingFieldUi;
  phone: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  hpDocumentPackets?: TDocPacketGetUI[];
  tenantResidencyInfoCards: TTenantResidencyInfoUI[];
};

export type TProfileDetails = {
  name: TEditingField;
  phone: TEditingField;
  email: TEditingField;
  profilePicture: TEditingField;
  userId: string;
  hpDocumentPackets?: TDocPacketGet[];
  tenantResidencyInfoCards: TTenantResidencyInfo[];
};

export type TApplicationHPPacketUI = TDocPacketGetUI & {
  fails?: TFailUI[];
};
export type TApplicationHPPacket = TDocPacketGet & {
  fails: TFail;
};

export type TAnimalMetadataUI = {
  present: boolean;
  description: string | null;
} | null;

export type TAnimalMetadata = {
  present: boolean;
  description: string | null;
} | null;

export type TApplicationProfileUI = Omit<
  TProfileDetailsUI,
  'hpDocumentPackets'
> & {
  fails: TFailUI[];
  documentsFails: TFailUI[];
  hpDocumentPackets: TApplicationHPPacketUI[];
  animalMetadata: TAnimalMetadataUI;
};
export type TApplicationProfile = Omit<TProfileDetails, 'hpDocumentPackets'> & {
  fails: TFail;
  hpDocumentPackets: TApplicationHPPacket[];
  specifiedAnimalMetadata: TAnimalMetadata;
};

export type TSaMetadataUpdateUI = {
  animalId: string;
  hasSaDocs?: TYesNoOptionType;
  isObservable: TYesNoOptionType;
  description?: string | null;
};
export type TSaMetadataUpdate = {
  animalId: string;
  trainedForObservableDisability: boolean;
  description?: string | null;
};

export type TEsaMetadataUpdateUI = {
  animalId: string;
  hasEsa: TYesNoOptionType;
  description?: string | null;
};
export type TEsaMetadataUpdate = {
  animalId: string;
  hasEsaLetterForAnimal: boolean;
  description?: string | null;
};

export type TOnboardTenantUI = {
  name: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string;
};
export type TOnboardTenantDB = {
  name: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string;
};
