import { useAppSelector } from 'redux/store';
import { PrivateRouteType } from 'routes/Routes.types';
import { privateRoutes } from 'routes/RoutesData';

const useFilterRoutes = () => {
  const user = useAppSelector(state => state.userSlice.user);
  if (user?.hasTraining) {
    return privateRoutes;
  }
  const filteredPrivateRoutes: PrivateRouteType[] = privateRoutes.filter(
    item => item.title !== 'Curriculum',
  );
  return filteredPrivateRoutes;
};

export default useFilterRoutes;
