import { FontWeights } from 'core/CssVariables';
import { Col } from 'components/Base/Grid';
import { Text } from 'components/Base/Typography';
import Button, { EButtonColors } from 'components/Base/Button';
import { useRef } from 'react';
import CreateAnimalModal from 'pages/private/Animal/components/CreateAnimal/CreateAnimalModal';
import { useNavigate } from 'react-router-dom';
import {
  SCompleteAnimalBar,
  SCompleteAnimalBarContent,
} from './CompleteAnimalBar.style';
import { TCompleteAnimalBarProps } from './CompleteAnimalBar.types';

const CompleteAnimalBar = ({ data }: TCompleteAnimalBarProps) => {
  const navigate = useNavigate();
  const createAnimalModalRef = useRef<{
    open: (id: string, actionType: string, url: string) => void;
  }>({
    open: () => {
      /* do nothing */
    },
  });
  if (!data || !data.count) return null;
  const { count, animalId, applicationId, incompleteResource } = data;
  const url = `/app/application/application-complete/${applicationId}`;
  const onCLick = () => {
    if (incompleteResource === 'ANIMAL') {
      createAnimalModalRef.current.open(
        animalId,
        'complete',
        applicationId ? url : '',
      );
      return;
    }
    if (applicationId) {
      navigate(url);
    }
  };

  return (
    <>
      <CreateAnimalModal ref={createAnimalModalRef} />
      <SCompleteAnimalBar justify="center">
        <SCompleteAnimalBarContent align="middle">
          <Col>
            <Text fontWeight={FontWeights.SemiBold} ellipsis>
              {count > 1
                ? `There are ${count} animals need to complete:`
                : `There is an animal need to complete:`}
            </Text>
          </Col>
          <Col className="ml-3">
            <Button
              onClick={onCLick}
              type="primary"
              color={EButtonColors.Warning}
              size="small"
            >
              {count > 1 ? 'Complete Animals' : 'Complete Animal'}
            </Button>
          </Col>
        </SCompleteAnimalBarContent>
      </SCompleteAnimalBar>
    </>
  );
};

export default CompleteAnimalBar;
