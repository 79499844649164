import React, { useEffect } from 'react';

export const useReloadAllTabsChannel = () => {
  const reloadChannel = React.useMemo(() => {
    return new BroadcastChannel('reloadTabs');
  }, []);

  const reloadTabs = () => {
    reloadChannel.postMessage('reloadTabs');
    window.location.reload();
  };
  useEffect(() => {
    reloadChannel.addEventListener('message', event => {
      if (event.data === 'reloadTabs') {
        window.location.reload();
      }
    });
    return () => {
      reloadChannel.close();
    };
  }, []);

  return { reloadTabs };
};

export default useReloadAllTabsChannel;
