import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TTenantDataUI } from 'data/types/tenant.types';
import { RoutesPaths } from 'routes/Routes.types';
import { tenantApi } from '../apiSlice/tenant.slices';
import { AuthAmplify } from '../apiSlice/authAmplify.slice';
import { TUserSliceState } from '../store.types';

const initialState: TUserSliceState = {
  isLoading: !localStorage.getItem('user'),
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  impersonateByTenant: localStorage.getItem('tenantId')
    ? localStorage.getItem('tenantId')
    : null,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<TTenantDataUI>) {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setImpersonateByTenant(state, action: PayloadAction<string>) {
      state.impersonateByTenant = action.payload;
    },
    removeImpersonateByTenant(state) {
      window.location.replace(`/${RoutesPaths.LOGIN}`);
      state.impersonateByTenant = null;
      localStorage.clear();
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      AuthAmplify.endpoints.currentAuthUserAmplify.matchRejected,
      state => {
        state.user = null;
        localStorage.removeItem('user');
      },
    );
    builder.addMatcher(
      tenantApi.endpoints.getTenantProfile.matchPending,
      state => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      tenantApi.endpoints.getTenantProfile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.isLoading = false;
        localStorage.setItem('user', JSON.stringify(payload));
      },
    );
    builder.addMatcher(
      AuthAmplify.endpoints.signOutAmplify.matchFulfilled,
      state => {
        state.user = null;
      },
    );
  },
});

export default userSlice;
