/* eslint-disable @typescript-eslint/no-explicit-any */
export type TOptionTypeUi = {
  id?: string;
  __isNew__?: boolean;
  value: string | any;
  label: string;
};

export type TOptionType = {
  id?: string;
  value?: string;
};

export type TPredefinedOptionType = {
  predefinedId?: string;
  value?: string;
};

export enum EVerificationStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
}
export enum EVerificationStatusUi {
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
  PENDING = 'Pending',
}

export type TProfilePictureUi = {
  name: string;
  url: string;
};

export enum EApplicationStatuses {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
}

export enum EApplicationStatusesUi {
  PENDING = 'Pending',
  REJECTED = 'Not Approved',
  APPROVED = 'Approved',
  ARCHIVED = 'Archived',
}

export enum EAnimalStatuses {
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  AUTHORIZED = 'AUTHORIZED',
  UN_AUTHORIZED = 'UN_AUTHORIZED',
}

export enum EAnimalStatusesUi {
  INCOMPLETE = 'Incomplete',
  PENDING = 'Pending',
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
  AUTHORIZED = 'Authorized',
  UN_AUTHORIZED = 'Unauthorized',
}

export type TPredefinedOptionTypeUi = TOptionTypeUi;

export type TCustomSpeciesOptionTypeUi = {
  id: null;
  value: string;
  predefined: boolean;
};

export type TCustomOptionTypeUi = {
  id: null;
  value: string;
};

export type TYesNoOptionType = 'Yes' | 'No' | null;

export type TVetRecordTypeUI = {
  predefined?: string;
  custom?: string;
};

export type TVetRecordType = {
  predefined: string;
  value: string;
};

export type TExpirationDateType = {
  expirable: boolean;
  date: string;
};

export type TFileDataType = {
  createdBy: string;
  createdDate: string;
  documentFileId: string;
  name: string;
  url: string;
};

export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export type TSelectValueUI = TOptionTypeUi | undefined;
export type TSelectValue = {
  predefinedId: string | null;
  value: string | null;
};

export enum EValueEditingStates {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export enum EValueEditingStatesUi {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export enum EVetRecordTypeEnum {
  'Other' = 'OTHER',
  'Rabies' = 'RABIES',
}

export type TEditingField = {
  predefinedId: string | null;
  value: string | null;
  allowedOperations?: EValueEditingStates[];
};

export type TEditingFieldUi = {
  id: string;
  value: string;
  canEdit: boolean;
  canRemove: boolean;
  canAdd: boolean;
};

export type TEditingBooleanField = {
  value: boolean;
  allowedOperations: EValueEditingStates[];
};

export type TEditingBooleanFieldUi = {
  value: boolean;
  canEdit: boolean;
};

export type TEditingYesNoFieldUi = {
  value: TYesNoOptionType;
  canEdit: boolean;
};

export type TEditingYesNoField = TEditingBooleanField;

export enum EGenderUi {
  FEMALE = 'Female',
  MALE = 'Male',
}

export enum EGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum EReproductive {
  SPAYED = 'Spayed',
  NEUTERED = 'Neutered',
}

export enum EGenderValue {
  FEMALE = 'female',
  MALE = 'male',
}

export enum EFailTypeUI {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
export enum EFailType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type TFailUI = { field: string; reason: string; type: EFailTypeUI };
export type TFail = { [key: string]: { reason: string; type: EFailType } };

export enum EFileTypeUI {
  VIDEO = 'video',
  PHOTO = 'image',
  OTHER = 'other',
}

export enum EFileTypeDB {
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  OTHER = 'other',
}

export type TFileUI = {
  url: string;
  name: string;
  type: EFileTypeUI;
};

export type TFileDB = {
  url: string;
  name: string;
  type?: EFileTypeDB;
};

export type TStatusChangeInfoUI = {
  by?: string;
  date?: string;
  reason?: string;
};

export type TStatusChangeInfoDB = {
  by?: string;
  date?: string;
  reason?: string;
};
