import React, { FC } from 'react';
import useServiceWorker from 'hooks/webApiHooks/useServiceWorker';
import { Title } from 'components/Base/Typography';
import Modal, { ModalWidthEnum } from 'components/Base/Modal';
import Button from 'components/Base/Button';
import { Col, Row } from '../Grid';

export const WebApisLayer: FC = ({ children }) => {
  const { showUpdateModal, onConfirm } = useServiceWorker();
  return (
    <>
      <Modal
        size={ModalWidthEnum.Small}
        onOk={onConfirm}
        visible={showUpdateModal}
        closable={false}
        footer={
          <Row justify="end" className="w-100">
            <Col className="text-right">
              <Button onClick={onConfirm}>Update</Button>
            </Col>
          </Row>
        }
      >
        <Title fontLevel={3}>A new version of application is available.</Title>
      </Modal>
      {children}
    </>
  );
};

export default WebApisLayer;
