import styled from 'styled-components';
import AntdLayout from 'antd/es/layout';
import { Screens } from 'core/CssVariables';

const { Content } = AntdLayout;

export const SLayoutWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export const SMainContentWrapper = styled(Content)<{
  incompleteAnimal?: boolean;
}>`
  height: ${({ incompleteAnimal }) =>
    `calc(100vh - ${incompleteAnimal ? '110px' : '64px'})`};
  padding: ${({ incompleteAnimal }) =>
    `${incompleteAnimal ? '110px' : '64px'} 0px 24px 0px`};
  @media (max-width: ${Screens.ScreensXXL}) {
    width: 100%;
    padding: ${({ incompleteAnimal }) =>
      `${incompleteAnimal ? '110px' : '64px'} 20px 24px 20px`};
  }
`;
