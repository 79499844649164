import pick from 'lodash/pick';
import omit from 'lodash/omit';
import moment from 'moment';
import { formattedDate } from 'helpers/utils';
import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TDeleteConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  EAnimalActionDB,
  EAnimalActionUI,
  EAnimalTypes,
  EAnimalTypesUI,
  EAuthorizationStatus,
  EAuthorizationStatusUI,
  EMoveOutReasonTypeDB,
  EMoveOutReasonTypeUI,
  TAnimalApplicationData,
  TAnimalApplicationDataUI,
  TAnimalBirthDate,
  TAnimalBirthDateUi,
  TAnimalBreedUpdate,
  TAnimalBreedUpdateUi,
  TAnimalDetails,
  TAnimalDetailsById,
  TAnimalDetailsByIdUi,
  TAnimalDetailsGenderField,
  TAnimalDetailsGenderFieldUi,
  TAnimalDetailsUi,
  TAnimalFormData,
  TAnimalFormDataUI,
  TAnimalGenderUpdate,
  TAnimalGenderUpdateUi,
  TAnimalGet,
  TAnimalHouseTrainedUpdate,
  TAnimalHouseTrainedUpdateUi,
  TAnimalNameUpdate,
  TAnimalNameUpdateUi,
  TAnimalPostUI,
  TAnimalPrimaryColorUpdate,
  TAnimalPrimaryColorUpdateUi,
  TAnimalProfileUpdate,
  TAnimalProfileUpdateUi,
  TAnimalReproductiveUpdate,
  TAnimalReproductiveUpdateUi,
  TAnimalSecondaryColorUpdate,
  TAnimalSecondaryColorUpdateUi,
  TAnimalSpeciesUpdate,
  TAnimalSpeciesUpdateUi,
  TAnimalType,
  TAnimalTypeConversion,
  TAnimalTypeConversionUi,
  TAnimalTypeUI,
  TAnimalVetRecordCreate,
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordsList,
  TAnimalVetRecordsListUi,
  TAnimalWeightUpdate,
  TAnimalWeightUpdateUi,
  TApplicationAnimal,
  TApplicationAnimalUI,
  TApplicationSADocPacketGet,
  TApplicationSADocPacketGetUI,
  TApplicationVetRecords,
  TApplicationVetRecordsUI,
  TBreedType,
  TBreedTypeUi,
  TCompleteAnimalFormData,
  TCompleteAnimalFormDataUI,
  TConfirmPayment,
  TConfirmPaymentUi,
  TConvertAnimalToPet,
  TConvertAnimalToPetUI,
  TEditingWeightField,
  TEditingWeightFieldUi,
  TGender,
  TGenderUI,
  TMoveOutFormDB,
  TMoveOutFormUI,
  TUnAuthorizedResidencyDB,
  TUnAuthorizedResidencyUI,
  TVetRecord,
  TVetRecordDocuments,
  TVetRecordDocumentsUI,
  TVetRecordExpirationUpdate,
  TVetRecordExpirationUpdateUI,
  TVetRecordTypeNameUpdate,
  TVetRecordTypeNameUpdateUI,
  TVetRecordUI,
  EConvertAnimalStatusUI,
  EConvertAnimalStatus,
  TAnimalMetadata,
  TAnimalMetadataUi,
  TConvertAnimalToEsaUI,
  TConvertAnimalToEsa,
  TBlockedAction,
  TBlockedActionUI,
  TUpdateAnimalConvHPDocsUI,
  TUpdateAnimalConvHPDocs,
  TConvertAnimalToSa,
  TConvertAnimalToSaUI,
  TMoveOutFromResidencyFormUI,
  TMoveOutFromResidencyFormDB,
  TIncompleteAnimalsDataUI,
  TIncompleteAnimalsDataDB,
} from 'data/types/animal.types';
import { TFile } from 'data/types/storage.types';
import {
  getSADocPacketConvertor,
  trainerInfoConvertor,
} from './animalTraining.convertors';
import {
  animalStatusConverter,
  applicationStatusConvertor,
  editingFieldConvertor,
  editingYesNoFieldConvertor,
  failsConvertor,
  genderEnumConvertor,
  selectCustomOptionConverter,
  selectCustomSpeciesOptionConverter,
  selectOptionConverter,
  selectOptionPredefinedConverter,
  verificationStatusConverter,
  yesNoOptionConverter,
} from './general.converters';
import {
  EGender,
  EGenderValue,
  EReproductive,
  EValueEditingStates,
  EVetRecordTypeEnum,
  TOptionType,
  TOptionTypeUi,
} from '../types/generalTypes';
import {
  getHPDocPacketsConvertor,
  hpContactInfoConvertor,
  hpDocTypeConvertor,
  hpVisitationConvertor,
} from './docPacket.convertors';

export const animalTypesConvertor: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: data => {
    return EAnimalTypesUI[EAnimalTypes[data] as keyof typeof EAnimalTypesUI];
  },
  toDb: data => {
    return EAnimalTypes[EAnimalTypesUI[data] as keyof typeof EAnimalTypes];
  },
};

export const authorizationStatusConvertor: TFetchConverter<
  EAuthorizationStatusUI,
  EAuthorizationStatus
> = {
  fromDb: data => {
    const status: { [key in EAuthorizationStatus]: EAuthorizationStatusUI } = {
      [EAuthorizationStatus.AUTHORIZED]: EAuthorizationStatusUI.AUTHORIZED,
      [EAuthorizationStatus.UN_AUTHORIZED]:
        EAuthorizationStatusUI.UN_AUTHORIZED,
      [EAuthorizationStatus.NO_STATUS]: EAuthorizationStatusUI.NO_STATUS,
    };
    return status[data];
  },
};

export const animalApplicationDataConverter: TFetchConverter<
  TAnimalApplicationDataUI,
  TAnimalApplicationData
> = {
  fromDb: data => {
    return {
      id: data.id,
      creationTime: formattedDate(data.creationTime),
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      moveInDate: formattedDate(data.moveInDate),
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      status: applicationStatusConvertor.fromDb(data.status),
      unitNumber: data.unitNumber,
      rejectReason: data.rejectReason || '',
      complete: data.complete,
    };
  },
};
export const animalActionConverter: TConvertor<
  EAnimalActionUI,
  EAnimalActionDB
> = {
  fromDb: data => {
    const action: { [key in EAnimalActionDB]: EAnimalActionUI } = {
      [EAnimalActionDB.MOVE_OUT]: EAnimalActionUI.MOVE_OUT,
      [EAnimalActionDB.DELETE]: EAnimalActionUI.DELETE,
      [EAnimalActionDB.CONVERT]: EAnimalActionUI.CONVERT,
    };
    return action[data];
  },
  toDb: data => {
    const action: { [key in EAnimalActionUI]: EAnimalActionDB } = {
      [EAnimalActionUI.MOVE_OUT]: EAnimalActionDB.MOVE_OUT,
      [EAnimalActionUI.DELETE]: EAnimalActionDB.DELETE,
      [EAnimalActionUI.CONVERT]: EAnimalActionDB.CONVERT,
    };
    return action[data];
  },
};

export const animalBlockedActionConverter: TFetchConverter<
  TBlockedActionUI,
  TBlockedAction
> = {
  fromDb: data => ({
    animalAction: animalActionConverter.fromDb(data.animalAction),
    reason: data.reason,
  }),
};

export const reproductiveConvertor: TFetchConverter<EReproductive, EGender> = {
  fromDb: data => {
    const reproduction: {
      [key in EGender]: EReproductive;
    } = {
      [EGender.FEMALE]: EReproductive.SPAYED,
      [EGender.MALE]: EReproductive.NEUTERED,
    };
    return reproduction[data];
  },
};

export const animalConvertor: TFetchConverter<TAnimalPostUI, TAnimalGet> = {
  fromDb: data => ({
    applications:
      data?.applications.map(item =>
        animalApplicationDataConverter.fromDb(item),
      ) || [],
    authorizationStatus:
      data.authorization?.status &&
      authorizationStatusConvertor.fromDb(data.authorization.status),
    complete: data.complete,
    name: data.name,
    profilePicture: data.profilePicture,
    type: data.type,
    ...(data.status && {
      status: animalStatusConverter.fromDb(data.status),
    }),
    gender: genderEnumConvertor.fromDb(data.gender),
    reproduction:
      data.isReproductive !== null
        ? reproductiveConvertor.fromDb(data.gender)
        : null,
    species: data.species?.value,
    id: data.id,
    breed: data.breed?.value,
    weight: data.weight?.value,
    actions: (data.actions || [])?.map(action =>
      animalActionConverter.fromDb(action),
    ),
  }),
};

export const genderConvertor: TAuthConverter<TGenderUI, TGender, null, null> = {
  toDb: data => ({
    type: genderEnumConvertor.toDb(data.gender),
    isReproductive: yesNoOptionConverter.toDb(data.neutered),
  }),
  fromDb: () => null,
};

export const createAnimalConvertor: TCreateConverter<
  TAnimalFormDataUI,
  TAnimalFormData
> = {
  toDb: data => ({
    animalType: animalTypesConvertor.toDb(data.animalType),
    name: data.name,
    birthDate: formattedDate(data.birthDate, 'YYYY-MM-DD'),
    breed: data.breed ? selectOptionConverter.toDb(data.breed) : null,
    species: selectOptionConverter.toDb(data.species),
    primaryColor: selectOptionConverter.toDb(data.primaryColor),
    secondaryColor1: data.secondaryColor1?.id || null,
    secondaryColor2: data.secondaryColor2?.id || null,
    weight: data.weight || null,
    profilePicture: data.profilePicture?.url,
    gender: genderConvertor.toDb(data),
    houseTrained: yesNoOptionConverter.toDb(data.houseTrained),
  }),
};

export const breedConverter: TCreateConverter<TBreedTypeUi, TBreedType> = {
  toDb: data => {
    if (data.id) {
      return selectOptionConverter.toDb(data);
    }
    return selectCustomOptionConverter.fromDb(data);
  },
};

export const completeAnimalConvertor: TCreateConverter<
  TCompleteAnimalFormDataUI,
  TCompleteAnimalFormData
> = {
  toDb: data => ({
    animalId: data.animalId,
    name: data.name,
    animalType: data.type.value,
    birthDate: formattedDate(data.birthDate, 'YYYY-MM-DD'),
    species: data.species.id
      ? selectOptionConverter.toDb(data.species)
      : selectCustomSpeciesOptionConverter.fromDb(data.species),
    breed: data.breed ? breedConverter.toDb(data.breed) : null,
    primaryColor: data.primaryColor.id
      ? selectOptionConverter.toDb(data.primaryColor)
      : selectCustomOptionConverter.fromDb(data.primaryColor),
    secondaryColor1: data.secondaryColor1?.id || null,
    secondaryColor2: data.secondaryColor2?.id || null,
    weight: data.weight || null,
    profilePicture: (data.profilePicture as TFile)?.url
      ? (data.profilePicture as TFile).url
      : (data.profilePicture as string),
    gender: genderConvertor.toDb(data),
    houseTrained: yesNoOptionConverter.toDb(data.houseTrained),
  }),
};

export const updateVetRecordDocumentsConvertor: TCreateConverter<
  TVetRecordDocumentsUI,
  TVetRecordDocuments
> = {
  toDb: data => ({
    animalId: data.animalId,
    vetRecordId: data.vetRecordId,
    newFiles: (data.docs || []).map(file => file),
    removedDocumentsId: data.removedDocumentsId || [],
  }),
};

export const updateAnimalTypeConvertor: TCreateConverter<
  TAnimalTypeUI,
  TAnimalType
> = {
  toDb: data => ({
    animalId: data.animalId,
    newType: animalTypesConvertor.toDb(data.type),
  }),
};

export const deleteVetRecordConvertor: TDeleteConverter<
  TVetRecordUI,
  TVetRecord
> = {
  toDb: data => ({
    animalId: data.animalId,
    vetRecordId: data.vetRecordId,
  }),
};

export const vetRecordTypeUpdateConverter: TCreateConverter<
  TVetRecordTypeNameUpdateUI,
  TVetRecordTypeNameUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      type:
        data.type === 'Rabies'
          ? {
              predefined:
                EVetRecordTypeEnum[
                  data.type as keyof typeof EVetRecordTypeEnum
                ],
            }
          : { custom: data.type },
    };
  },
};

export const vetRecordExpirationUpdateConverter: TCreateConverter<
  TVetRecordExpirationUpdateUI,
  TVetRecordExpirationUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      expiration: {
        expirable: true,
        date: data.expirationDate,
      },
    };
  },
};

export const createVetRecordConvertor: TCreateConverter<
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordCreate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      expiration: {
        expirable: true,
        date: data.expirationDate,
      },
      files: data.files,
      type:
        data.type !== 'Other'
          ? {
              predefined:
                EVetRecordTypeEnum[
                  data.type as keyof typeof EVetRecordTypeEnum
                ],
            }
          : { custom: data.docName },
    };
  },
};

export const confirmPaymentConvertor: TCreateConverter<
  TConfirmPaymentUi,
  TConfirmPayment
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      leasePacketId: data.leasePacketId,
    };
  },
};

export const getAnimalInfoListConvertor: TFetchConverter<
  TOptionTypeUi[],
  TOptionType[]
> = {
  fromDb: data => data?.map(item => selectOptionConverter.fromDb(item)) || [],
};

export const editingWeightFieldConvertor: TFetchConverter<
  TEditingWeightFieldUi,
  TEditingWeightField
> = {
  fromDb: data => {
    return {
      value: data?.value ? data.value.toString() : '',
      unit: data?.unit || 'LBS',
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
    };
  },
};

export const animalDetailsGenderConverter: TFetchConverter<
  TAnimalDetailsGenderFieldUi,
  TAnimalDetailsGenderField
> = {
  fromDb: data => {
    return {
      value: genderEnumConvertor.fromDb(data.value),
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
    };
  },
};

export const animalVetRecordConvertor: TFetchConverter<
  TAnimalVetRecordsListUi,
  TAnimalVetRecordsList
> = {
  fromDb: data => {
    return {
      type: data.type,
      canEdit: data.locked,
      locked: data.locked,
      id: data.id,
      expiration: data.expiration,
      isExpired: data.IsExpired,
      documentFiles: data.documentFiles,
      ...(data.verificationStatus && {
        status: verificationStatusConverter.fromDb(data.verificationStatus),
      }),
    };
  },
};

export const applicationSADocPacketConvertor: TFetchConverter<
  TApplicationSADocPacketGetUI,
  TApplicationSADocPacketGet
> = {
  fromDb: data => ({
    id: data.id,
    trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
    saLetters: data.saLetters,
    trainedFor: data.trainedFor,
    startTime: data.trainingStartDate,
    endTime: data.trainingEndDate,
    canEdit: data.modifiable,
    status: verificationStatusConverter.fromDb(data.verificationStatus),
    fails: failsConvertor.fromDb(data.fails || {}),
  }),
};

export const animalConversionStatusConvertor: TFetchConverter<
  EConvertAnimalStatusUI,
  EConvertAnimalStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EConvertAnimalStatus]: EConvertAnimalStatusUI;
    } = {
      [EConvertAnimalStatus.APPROVED]: EConvertAnimalStatusUI.APPROVED,
      [EConvertAnimalStatus.PENDING]: EConvertAnimalStatusUI.PENDING,
      [EConvertAnimalStatus.REJECTED]: EConvertAnimalStatusUI.REJECTED,
    };
    return statuses[data];
  },
};

export const animalTypeConversionListConvertor: TFetchConverter<
  TAnimalTypeConversionUi,
  TAnimalTypeConversion
> = {
  fromDb: data => ({
    createdAt: formattedDate(data.createdAt),
    fromType: animalTypesConvertor.fromDb(data.fromType),
    hpDocumentPacket: data.hpDocumentPacket
      ? getHPDocPacketsConvertor.fromDb(data.hpDocumentPacket)
      : null,
    saDocumentPacket: data.saDocumentPacket
      ? applicationSADocPacketConvertor.fromDb(data.saDocumentPacket)
      : null,
    id: data.id,
    reason: data.reason,
    status: animalConversionStatusConvertor.fromDb(data.status),
    toType: animalTypesConvertor.fromDb(data.toType),
  }),
};

export const animalDetailsConverter: TFetchConverter<
  TAnimalDetailsUi,
  TAnimalDetails
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: editingFieldConvertor.fromDb(data.name),
      profilePicture: editingFieldConvertor.fromDb(data.profilePicture),
      species: editingFieldConvertor.fromDb(data.species),
      breed: editingFieldConvertor.fromDb(data.breed),
      conversions:
        data.conversions?.map(item =>
          animalTypeConversionListConvertor.fromDb(item),
        ) || [],
      primaryColor: editingFieldConvertor.fromDb(data.primaryColor),
      secondaryColor1: editingFieldConvertor.fromDb(data.secondaryColor1),
      secondaryColor2: editingFieldConvertor.fromDb(data.secondaryColor2),
      birthDate: editingFieldConvertor.fromDb(data.birthDate),
      weight: editingWeightFieldConvertor.fromDb(data.weight),
      isHouseTrained: editingYesNoFieldConvertor.fromDb(data.isHouseTrained),
      gender: animalDetailsGenderConverter.fromDb(data.gender),
      isReproductive: editingYesNoFieldConvertor.fromDb(data.isReproductive),
      type: animalTypesConvertor.fromDb(data.type),
      ...(data.status && {
        status: animalStatusConverter.fromDb(data.status),
      }),
      authorizationStatus:
        data.authorization?.status &&
        authorizationStatusConvertor.fromDb(data.authorization.status),
      vetRecordList:
        data.vetRecords?.map(item => animalVetRecordConvertor.fromDb(item)) ||
        [],

      packets: (data.saDocumentPackets || []).map(item =>
        getSADocPacketConvertor.fromDb(item),
      ),
      actions: (data.actions || [])?.map(action =>
        animalActionConverter.fromDb(action),
      ),
      blockedActions: (data.blockedActions || [])?.map(action =>
        animalBlockedActionConverter.fromDb(action),
      ),
    };
  },
};

export const animalMetadataConverter: TFetchConverter<
  TAnimalMetadataUi,
  TAnimalMetadata
> = {
  fromDb: data => {
    return {
      description: data.description,
      hasEsaLetterForAnimal: yesNoOptionConverter.fromDb(
        data.hasEsaLetterForAnimal,
      ),
      trainedForObservableDisability: yesNoOptionConverter.fromDb(
        data.trainedForObservableDisability,
      ),
      trainedForObservableDisabilityDescription:
        data.trainedForObservableDisabilityDescription,
    };
  },
};

export const animalDetailsByIdConverter: TFetchConverter<
  TAnimalDetailsByIdUi,
  TAnimalDetailsById
> = {
  fromDb: data => {
    return {
      name: data.name?.value,
      profilePicture: data.profilePicture?.value,
      species: data.species?.value
        ? selectOptionPredefinedConverter.fromDb(data.species)
        : null,
      type: data.type
        ? {
            value: animalTypesConvertor.fromDb(data.type),
            label: animalTypesConvertor.fromDb(data.type),
          }
        : null,
      breed: data.breed?.value
        ? selectOptionPredefinedConverter.fromDb(data.breed)
        : null,
      primaryColor: data.primaryColor?.value
        ? selectOptionPredefinedConverter.fromDb(data.primaryColor)
        : null,
      secondaryColor1: data.secondaryColor1?.value
        ? selectOptionPredefinedConverter.fromDb(data.secondaryColor1)
        : null,
      secondaryColor2: data.secondaryColor2?.value
        ? selectOptionPredefinedConverter.fromDb(data.secondaryColor2)
        : null,
      birthDate: data.birthDate?.value ? moment(data.birthDate?.value) : null,
      weight: data.weight?.value,
      houseTrained: yesNoOptionConverter.fromDb(data.isHouseTrained?.value),
      gender: EGenderValue[data.gender?.value as keyof typeof EGenderValue],
      neutered: yesNoOptionConverter.fromDb(data.isReproductive?.value),
    };
  },
};

export const animalBreedUpdateConverter: TCreateConverter<
  TAnimalBreedUpdateUi,
  TAnimalBreedUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      breed: selectOptionConverter.toDb(data.value),
    };
  },
};
export const animalSpeciesUpdateConverter: TCreateConverter<
  TAnimalSpeciesUpdateUi,
  TAnimalSpeciesUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      species: selectOptionConverter.toDb(data.value),
    };
  },
};
export const animalPrimaryColorUpdateConverter: TCreateConverter<
  TAnimalPrimaryColorUpdateUi,
  TAnimalPrimaryColorUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      primaryColor: selectOptionConverter.toDb(data.value),
    };
  },
};
export const animalSecondaryColorUpdateConverter: TCreateConverter<
  TAnimalSecondaryColorUpdateUi,
  TAnimalSecondaryColorUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      colorId: data.value,
    };
  },
};

export const animalHouseTrainedUpdateConverter: TCreateConverter<
  TAnimalHouseTrainedUpdateUi,
  TAnimalHouseTrainedUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      houseTrained: yesNoOptionConverter.toDb(data.value),
    };
  },
};
export const animalNameUpdateConverter: TCreateConverter<
  TAnimalNameUpdateUi,
  TAnimalNameUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      name: data.value,
    };
  },
};
export const animalReproductiveUpdateConverter: TCreateConverter<
  TAnimalReproductiveUpdateUi,
  TAnimalReproductiveUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      reproductive: yesNoOptionConverter.toDb(data.value),
    };
  },
};
export const animalWeightUpdateConverter: TCreateConverter<
  TAnimalWeightUpdateUi,
  TAnimalWeightUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      weight: data.value || null,
      unit: 'LBS',
    };
  },
};

export const animalProfilePictureUpdateConverter: TCreateConverter<
  TAnimalProfileUpdateUi,
  TAnimalProfileUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      profilePicture: data.value,
    };
  },
};

export const animalGenderUpdateConverter: TCreateConverter<
  TAnimalGenderUpdateUi,
  TAnimalGenderUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      gender: genderEnumConvertor.toDb(data.value),
    };
  },
};
export const animalBirthUpdateConverter: TCreateConverter<
  TAnimalBirthDateUi,
  TAnimalBirthDate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      birthDate: formattedDate(data.value, 'YYYY-MM-DD'),
    };
  },
};

export const applicationAnimalVetRecordsConvertor: TFetchConverter<
  TApplicationVetRecordsUI[],
  TApplicationVetRecords[]
> = {
  fromDb: data =>
    data?.map(item => ({
      ...animalVetRecordConvertor.fromDb(item),
      fails: failsConvertor.fromDb(item.fails || {}),
    })) || [],
};

export const applicationAnimalConvertor: TFetchConverter<
  TApplicationAnimalUI,
  TApplicationAnimal
> = {
  fromDb: data => {
    const documentsFails = pick(data.fails || {}, [
      'saDocumentPackets',
      'vetRecords',
    ]);

    return {
      id: data.id,
      name: editingFieldConvertor.fromDb(data.name),
      profilePicture: editingFieldConvertor.fromDb(data.profilePicture),
      species: editingFieldConvertor.fromDb(data.species),
      breed: editingFieldConvertor.fromDb(data.breed),
      primaryColor: editingFieldConvertor.fromDb(data.primaryColor),
      secondaryColor1: editingFieldConvertor.fromDb(data.secondaryColor1),
      secondaryColor2: editingFieldConvertor.fromDb(data.secondaryColor2),
      birthDate: editingFieldConvertor.fromDb(data.birthDate),
      weight: editingWeightFieldConvertor.fromDb(data.weight),
      isHouseTrained: editingYesNoFieldConvertor.fromDb(data.isHouseTrained),
      gender: animalDetailsGenderConverter.fromDb(data.gender),
      isReproductive: editingYesNoFieldConvertor.fromDb(data.isReproductive),
      type: animalTypesConvertor.fromDb(data.type),
      ...(data.status && {
        status: animalStatusConverter.fromDb(data.status),
      }),
      vetRecordList: applicationAnimalVetRecordsConvertor.fromDb(
        data.vetRecords || [],
      ),
      packets: (data.saDocumentPackets || []).map(item =>
        applicationSADocPacketConvertor.fromDb(item),
      ),
      fails: failsConvertor.fromDb(
        omit(data.fails || {}, ['saDocumentPackets', 'vetRecords']),
      ),
      documentsFails: failsConvertor.fromDb(documentsFails),
    };
  },
};

export const moveOutReasonTypeConverter: TConvertor<
  EMoveOutReasonTypeUI,
  EMoveOutReasonTypeDB
> = {
  fromDb: data => {
    const reason: { [key in EMoveOutReasonTypeDB]: EMoveOutReasonTypeUI } = {
      [EMoveOutReasonTypeDB.ANIMAL_HAS_PASSED_AWAY]:
        EMoveOutReasonTypeUI.ANIMAL_HAS_PASSED_AWAY,
      [EMoveOutReasonTypeDB.ANIMAL_HAS_RE_HOMED]:
        EMoveOutReasonTypeUI.ANIMAL_HAS_RE_HOMED,
    };
    return reason[data];
  },
  toDb: data => {
    const reason: { [key in EMoveOutReasonTypeUI]: EMoveOutReasonTypeDB } = {
      [EMoveOutReasonTypeUI.ANIMAL_HAS_PASSED_AWAY]:
        EMoveOutReasonTypeDB.ANIMAL_HAS_PASSED_AWAY,
      [EMoveOutReasonTypeUI.ANIMAL_HAS_RE_HOMED]:
        EMoveOutReasonTypeDB.ANIMAL_HAS_RE_HOMED,
    };
    return reason[data];
  },
};

export const animalMoveOutConvertor: TCreateConverter<
  TMoveOutFormUI,
  TMoveOutFormDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      reason: data.other
        ? { other: data.other }
        : { predefined: moveOutReasonTypeConverter.toDb(data.reason) },
      ...(data.reason === EMoveOutReasonTypeUI.ANIMAL_HAS_PASSED_AWAY && {
        deleteAnimal: !!data.deleteAnimal,
      }),
    };
  },
};

export const animalMoveOutFromResidenciesConvertor: TCreateConverter<
  TMoveOutFromResidencyFormUI,
  TMoveOutFromResidencyFormDB
> = {
  toDb: data => {
    return {
      animalIds: data.animalIds,
      residencyId: data.residencyId,
      moveOutReason: data.other
        ? { other: data.other }
        : { predefined: moveOutReasonTypeConverter.toDb(data.reason) },
      ...(data.reason === EMoveOutReasonTypeUI.ANIMAL_HAS_PASSED_AWAY && {
        deleteAnimal: !!data.deleteAnimal,
      }),
    };
  },
};

export const convertAnimalToPetConverter: TCreateConverter<
  TConvertAnimalToPetUI,
  TConvertAnimalToPet
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
    };
  },
};

export const convertAnimalToESAConverter: TCreateConverter<
  TConvertAnimalToEsaUI,
  TConvertAnimalToEsa
> = {
  toDb: data => ({
    animalId: data.animalId,
    hpContactInfo:
      data.name || data.emailAddress || data.phoneNumber || data.faxNumber
        ? hpContactInfoConvertor.toDb({
            name: data.name,
            emailAddress: data.emailAddress,
            phoneNumber: data.phoneNumber,
            faxNumber: data.faxNumber,
          })
        : undefined,
    hpVisitation: data.hpVisitation
      ? hpVisitationConvertor.toDb({
          type: data.hpVisitation,
          websites: data.websites?.filter(item => !!item),
        })
      : undefined,
    hpDocPacketLetter: data.hpDocPacketLetter,
  }),
};

export const convertAnimalToSAConverter: TCreateConverter<
  TConvertAnimalToSaUI,
  TConvertAnimalToSa
> = {
  toDb: data => {
    const trainerInfo = {
      name: data.name,
      emailAddress: data.emailAddress,
      faxNumber: data.faxNumber,
      phoneNumber: data.phoneNumber,
    };
    return {
      animalId: data.animalId,
      hpContactInfo:
        data.hpDocFormName ||
        data.hpDocFormEmailAddress ||
        data.hpDocFormPhoneNumber ||
        data.hpDocFormFaxNumber
          ? hpContactInfoConvertor.toDb({
              name: data.hpDocFormName,
              emailAddress: data.hpDocFormEmailAddress,
              phoneNumber: data.hpDocFormPhoneNumber,
              faxNumber: data.hpDocFormFaxNumber,
            })
          : undefined,
      hpVisitation: data.hpVisitation
        ? hpVisitationConvertor.toDb({
            type: data.hpVisitation,
            websites: data.hpDocFormWebsites?.filter(item => !!item),
          })
        : undefined,
      hpDocPacketLetter: data.hpDocPacketLetter,
      trainerInfo: trainerInfoConvertor.toDb(trainerInfo),
      saLetters: data.saLetters,
      trainedFor: data.trainedFor || undefined,
      trainingStartDate: data.startTime
        ? formattedDate(data.startTime, 'YYYY-MM-DD')
        : undefined,
      trainingEndDate: data.endTime
        ? formattedDate(data.endTime, 'YYYY-MM-DD')
        : undefined,
    };
  },
};

export const updateAnimalConversionHPDocsConverter: TCreateConverter<
  TUpdateAnimalConvHPDocsUI,
  TUpdateAnimalConvHPDocs
> = {
  toDb: data => ({
    animalId: data.animalId,
    conversionId: data.conversionId,
    hpContactInfo:
      data.name || data.emailAddress || data.phoneNumber || data.faxNumber
        ? hpContactInfoConvertor.toDb({
            name: data.name,
            emailAddress: data.emailAddress,
            phoneNumber: data.phoneNumber,
            faxNumber: data.faxNumber,
          })
        : undefined,
    hpDocumentPacketType: data.hpDocType
      ? hpDocTypeConvertor.toDb(data.hpDocType)
      : undefined,
    hpVisitation: data.hpVisitation
      ? hpVisitationConvertor.toDb({
          type: data.hpVisitation,
          websites: data.websites?.filter(item => !!item),
        })
      : undefined,
    hpDocPacketLetter: data.hpDocPacketLetter,
  }),
};

export const unAuthorizedResidencyConverter: TFetchConverter<
  TUnAuthorizedResidencyUI,
  TUnAuthorizedResidencyDB
> = {
  fromDb: data => {
    return {
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
    };
  },
};

export const incompleteAnimalsData: TFetchConverter<
  TIncompleteAnimalsDataUI,
  TIncompleteAnimalsDataDB
> = {
  fromDb: data => {
    return {
      animalId: data.animalId || '',
      applicationId: data.applicationId || '',
      incompleteResource:
        data.incompleteResource === 'ANIMAL' ? 'ANIMAL' : 'APPLICATION',
      count: data.count,
    };
  },
};
