import styled from 'styled-components';
import {
  Modal as UIKitModal,
  ModalDeleteConfirmation as UIKitModalDeleteConfirmation,
  TModalDeleteProps,
} from '@landlord-tech/ui-kit-opp';

import { FontFamilies } from 'core/CssVariables';
import { TModalProps } from './modal.types';

export const SModal = styled(UIKitModal)<TModalProps>`
  &&& {
    .ant-modal-title {
      font-family: ${FontFamilies.FontSecondary};
    }
  }
`;

export const SModalDeleteConfirmation = styled(
  UIKitModalDeleteConfirmation,
)<TModalDeleteProps>`
  &&& {
    .ant-modal-title {
      font-family: ${FontFamilies.FontSecondary};
    }
  }
`;
