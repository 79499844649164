import { useState, useCallback, useEffect } from 'react';
import appSlice from 'redux/slices/app.slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import useReloadAllTabsChannel from './useReloadAllTabsChannel';

const useServiceWorker = () => {
  const { reloadTabs } = useReloadAllTabsChannel();
  const dispatch = useAppDispatch();

  const showUpdateModal = useAppSelector(
    state => state.appSlice.showUpdateModal,
  );
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null,
  );
  // called when a service worker
  // updates. this function is a callback
  // to the actual service worker
  // registration onUpdate.

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
    navigator.serviceWorker.getRegistrations().then(registrations => {
      const registrationsArr = registrations as ServiceWorkerRegistration[];
      if (registrationsArr[0]?.active && registrationsArr[0]?.waiting) {
        dispatch(appSlice.actions.setShowUpdateModal(true));
      }
    });
    return () => {
      if (waitingWorker) {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
      }
    };
  }, []);

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    dispatch(appSlice.actions.setShowUpdateModal(false));
    reloadTabs();
  }, [waitingWorker]);

  // register the service worker
  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    });
  }, [onSWUpdate]);

  const onConfirm = () => {
    reloadPage();
  };
  // useEffect(() => {
  //   if (showReload && waitingWorker && !toastsCount) {
  //     setToastsCount(1);
  //     toast({
  //       type: 'info',
  //       key: 'workerUpdateKey',
  //       onClose: () => closeAllToasts(),
  //       closeIcon: () => null,
  //       description: (
  //         <Text fontLevel={5}>
  //           A new version of app is available.&nbsp;
  //           <Button type="text" color={EButtonColors.Info} onClick={reloadPage}>
  //             Update
  //           </Button>
  //         </Text>
  //       ),
  //       duration: 0,
  //       placement: 'top',
  //       top: 64,
  //     });
  //   } else {
  //     toastClose('workerUpdateKey');
  //   }
  // }

  return { showUpdateModal, onConfirm };
};

export default useServiceWorker;
